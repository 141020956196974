html {
    scroll-behavior: smooth;
}

.catalog-content {
    &--after {
        margin-top: 3.2rem;
    }

    &--before {
        text-align: center;
    }


    &__products {

        margin-top: 1.6rem;

        background: lighten($colBgLight, 20%);
        padding: 1.6rem 1.6rem 1.6rem;
        
        .articles {
            margin-bottom: 0;
        
        }

        .pagination {
            margin-bottom: 0;
        }        

        article {
            background: #fff;
            
        }

    }    
}

.collapsible-block {

    margin-top: 1.6rem;
    
    & + .collapsible-block {
        margin-top: .8rem;
    }

    &--open {

        .collapsible-block {
            &__header {
                background: $colDarkBlue;
                &:hover {
                    background: $colDarkBlue;
                }                    
            }

            &__headline {
                color: #FFF;
                opacity: 1;
            }

            &__toggle {
                color: #FFF;
                transform: rotate(90deg);
                
                
            }          
            
            &__body {
                height: auto;      
            }
        }        

    }

    &__header {
        position: relative;
        background: $colLightBlue;
        cursor: pointer;
        &:hover {
            background: desaturate(darken($colLightBlue, 10%), 20%);
        }        
    }

    &__headline {
        color: $colDarkBlue;
        opacity: .75;
        font-size: 1.6rem;
        letter-spacing: 0.05px;
        font-weight: 300;
        padding: 1.2rem;
        margin: 0;
    }

    &__toggle {
        text-align: right;
        appearance: none;
        color: $colDarkBlue;;
        font-size: 2rem;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        background: none;
        border: none;      
        display: block;  
        cursor: pointer;
        transition: transform linear .25s, color linear .25s;

        &:focus {
            outline: none;
            font-weight: bold;
        }
    }

    &__body {

        overflow: hidden;    
    }
}

.text-block {
    color: $colDarkText;
    font-size: 1.5rem;
    overflow: hidden;

    > * {
        margin: .8rem 0 0;

        &:last-child {
            padding-bottom: 1.6rem;
        }
    }

    ul {
        display: inline-block;
        margin: 0 auto;
        text-align: left;
        li {
            margin-top: .8rem;
        }
        
    }    

    h3 {
        font-weight: bold;
        margin-top: 1.6rem;
        line-height: 1.2;
        font-size: 1.6rem;
    }

    p {
        margin: .8rem 0 0;
        
    }

    ul + p {
        margin-top: 1.6rem;
    }

    ul {
        margin-top: .8rem;
    }

    h3 + p {
         margin-top: .4rem;
    }

    table  {
        border: none;
        margin-top: 1.6rem;

        thead {
            tr:first-child {
                background: $colDarkBlue;
    
                th {
                    color: #fff;
                    font-size: 1.2rem;
                    border: none;

                }            
            } 
        }   
        tbody {
            tr {
                background: #FFF;
            }

            td, th {
                border: none;
                border-top: 2px solid $colDarkBlue;
                font-size: 1.2rem;
                vertical-align: top;
            }
            th {
                font-weight: bold;
            }
        }          
    }      

    &__button {
        display: inline-block;
        white-space: nowrap;
        padding: .8rem 1.6rem 1rem;
        background: #1AA0E2;
        color: #FFF;
        font-size: 1.6rem;
        cursor: pointer;
        text-align: center;
        text-decoration: none;
        margin-top: 1.6rem;
    }        
}    
