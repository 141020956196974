.gerl-landing {
    &__visual {
        
        aspect-ratio: 2/1;
        position: relative;
        background: #071E52;
        top: -2rem;

        grid-template-areas: 'title title' 'code code' 'logo logo';
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 2fr 1fr 1fr;

        gap: 2rem;

        display: grid;
        align-items: center;
        justify-content: center;

        &::after {
            content: '';
            position: absolute;
            left: -9999px;
            right: -9999px;
            top: 0;
            bottom: 0;
            background: #071E52;
            z-index: -1
        }
    }

    &__logo {
        grid-area: logo;
        align-self: end;
        text-align: right;
        padding: 2rem;
        

        img {
            
            max-width: 15rem;
            
        }
        
    } 

    &__coupon {
        grid-area: title;
        padding: 2rem;
        text-align: center;
        align-self: end;

        img {
            
            max-width: 40rem;
        }
    }

    &__code-input {
        grid-area: code;
        margin: 0 auto;
        max-width: 65rem;
        

        form {
            position: relative;
        }

        p {
            color: #FFF;
            font-size: 1.75rem;
        }

        input {
            appearance: none;
            font-size: 3rem;
            width: 100%;
            box-sizing: border-box;
            padding: 1rem;
            border-radius: 1rem;
            border: none;
            font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
            

        }

        button {
            appearance: none;
            position: absolute;
            overflow: hidden;
            right: 0;
            top: 0;
            bottom: 0;
            width: 6rem;
            background: #71CEF3;
            border-radius: 0 1rem 1rem 0;
            border: 0;
            text-indent: 100%;
            white-space: nowrap;
            padding: 0;
            cursor: pointer;

            &::after {
                content: '›';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-140%, -55%);
                font-size: 6rem;
                color: #FFF;
            }
            
        }
    }

}