.body-text {
    display: block;
    padding-bottom: 3.2rem;

    &--after {

        padding-top: 3.2rem;
        padding-bottom: 0;
    }

    h2 {
        text-transform: none;
        font-size: 2.4rem;
        margin: 3.2rem 0 2.4rem;    
        font-weight: bold;    
        letter-spacing: 0;
    }

    h3 {
        margin: 2.4rem 0 .8rem;
        font-size: 2.1rem;
        font-weight: bold; 
        letter-spacing: 0;

    }

    p, ul {
        font-size: 1.6rem;
        line-height: 1.4;
        font-weight: 200;
        margin: .8rem 0;
    }

    li {
        font-size: 1.6rem;
        line-height: 1.5;
        margin: 0;
    }

    table {
        margin: 3.2rem 0;
        width: 100%;
        
        th {
            font-weight: bold;
            text-align: left;
            vertical-align: bottom;
        }

        td {
            vertical-align: top;
            background: #FFF;

            &:first-child {
                font-weight: bold;
  
                
            } 
        }
    }

    &__products {
        background: lighten($colBgLight, 20%);
        padding: 1.6rem 1.6rem 1.6rem;
        
        .articles {
            margin-bottom: 0;
            

        }

        .pagination {
            margin-bottom: 0;
        }        

        article {
            background: #fff;
            
        }

    }
}