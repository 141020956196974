.landing-select {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    grid-gap: 1.5rem;
    
    margin-top: 5rem;

    &__extra {
        display: none;
        margin-top: 5rem;
        text-align: center;

        &--visible {
            display: block;
        }

        
        h3 {
            margin: 0 0 1rem;
        }

        

    }

    &__product {

        appearance: none;
        background: none;
        cursor: pointer;
        text-align: left;

        position: relative;
        aspect-ratio: 1/1;
        border: 2px solid #DDD;
        overflow: hidden;
        padding: 1rem;
        opacity: .75;

        &--selected {
            opacity: 1;
            border-color: #71CEF3;
        }
    }

    &__title {
        position: absolute;
        inset: 1rem;    
        color: #0077BF;
        font-family: "ff-dax-web-pro","Arial Narrow","Arial";
        z-index: 2;
        font-size: 1.5rem;
    }

    &__img {
        position: absolute;

        top: 6rem;
        bottom: 0;
        left: 0;
        right: 0;
        object-fit: contain;
        width: 100%;
        height: calc(100% - 6rem);


    }
}