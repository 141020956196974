.tabNav {

    & > ul {
        list-style: none;
        margin: 0;
        padding: 0;
        
        display: flex;
        flex-wrap: nowrap;
        
        display: -ms-flexbox;
        ms-flex-wrap: wrap;
        
        
        margin-top: 3rem;

        & > li {
            margin-right: .4rem;
            font-size: 1.8rem;
            @media (max-width: 740px) {
                font-size: 1.4rem;
            }

        }
    }

    & > ul > li > a {
        position: relative;
        font-family: "ff-dax-web-pro";
        font-weight: 500;
        text-decoration: none;
        display: block;
        padding: 1.4rem 2.4rem 1rem;
        line-height: 1;
        text-transform: uppercase;
        border-radius: 6px 6px 0 0;
        letter-spacing: .125rem;
        @media (max-width: 740px) {
            padding: 1.4rem 1.4rem 1rem;
        }

        background: #ACE0F8;
        color: #FFF;
        
        transition: background linear .25s, color linear .25s;


    }


    
    & > ul > .showsSubNav > a {
        background: $colBgDark;
        color: $colFontBgDark;

    }  
    
    & > ul > .current > a {
        background: $colBgDark;
        color: $colFontBgDark;
        transition: padding-bottom linear .25s;

    } 
    
    & > ul > .currentBg > a {
        background: $colBgDarkTransparent;
        color: $colFontBgDark;
        padding-bottom: 1rem;
        &:after {
            opacity: 0 !important;
        }

    }         

}

.tabs {
    padding: 1.2rem;
    border: 1px solid #0D66A2;
    
}

#info {
    & > p:last-child {
        margin-bottom: 0;
    }
}
