@supports (-ms-accelerator:true) {
	.teaserBox-01 {
		article {
			margin: 1%;
			float: left;
			width: 31%;
		}
	}
	

	
	.highlightProduct {
		float: right;
		width: 32%;
		padding-bottom: 63.65%;
		margin: 1%;
		position: relative;
		article {
			position: absolute;
			width: 100%;
			height: 100%;
			margin: 0;
			
		}	
	}
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
     /* IE10+ CSS styles go here */
	.teaserBox-01 {
		article {
			margin: 1%;
			float: left;
			width: 31%;
		}
	}
	
    .landingPageSimple {
        .mainVisual {
            margin-top: 0rem;        
        }
    }		
	
	.highlightProduct {
		float: right;
		width: 32%;
		padding-bottom: 63.65%;
		margin: 1%;
		position: relative;
		article {
			position: absolute;
			width: 100%;
			height: 100%;
			margin: 0;
			
		}	
	}     
}
