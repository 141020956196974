.depotLogos {
    margin: 1rem 0;
    li {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: end;
        -webkit-align-items: flex-end;
        -ms-flex-align: end;
                align-items: flex-end;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
                flex-wrap: wrap;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
                justify-content: space-between;

        
        @media (max-width: 540px) {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 1rem;

        }        
        span, a {
                
            width: auto;
            max-width: 120px;
            box-sizing: border-box;
            display: block;

            text-align: center;

            img {
                max-width: 120px;
                max-height: 64px;
                justify-content: space-between;
                display: -ms-flexbox;
                
                image-rendering: -webkit-optimize-contrast;
                image-rendering: -moz-crisp-edges;
                image-rendering: auto;    

            }
            &:first-child {
                padding-left: 0;
            }
            &:last-child {
                padding-right: 0;
            }            

            @media (max-width: 540px) {
                max-width: none;
                padding: 1rem !important;   
                
                img {
                    height: 8rem;
                    max-width: none;
                    object-fit: contain;
                    object-position: center bottom;
                    width: 100%;   
                }                 
                         
    
            }


    
            
        }
    }

}

.unslider {
    .unslider-nav {
        position: relative;
        padding-top: 10px;
        z-index: 2;
    }
}