.videos {
    display: flex;
    margin: 4rem -.5rem 0;
    flex-wrap: wrap;

    @media (max-width: 640px) {
        display: block;
        margin: 4rem 0 0;
    }

    &__title {
        margin: 1rem 0;
        font-weight: bold;
    }

    &__video {
        width: 50%;
        padding: 1rem;
        box-sizing: border-box;
        background: #daf2fe;
        border: .5rem solid #FFF;

        @media (max-width: 640px) {
            width: auto;
            margin: 1rem 0 0;
        }

    }

    &__article {
        display: inline-block;
        background: #004986;
        color: #FFF;
        text-decoration: none;
        border-radius: 0.5rem;
        font-size: 1.25rem;
        padding: .25rem;
    }

    &__search {
        display:  none;
    }
}