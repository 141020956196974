.depotContact {

    .catalog-content--before {
        text-align: left;
    } 

    aside {
        margin-top: 6.2rem;
    }
    
    .vcards {

        display: flex;
        flex-wrap: wrap;
        @media (max-width: 930px) {
            display: block;
        }
    }

    .vcard {
        flex: 50% 0 0;
        box-sizing: border-box;

        &:nth-child(even) {
            padding-left: 1rem;
        }

        &:nth-child(odd) {
            padding-right: 1rem;
        }

        @media (max-width: 930px) {
            display: block;

            &:nth-child(odd), &:nth-child(odd) {
                padding: 0;
            }         
        }
    }
    
}