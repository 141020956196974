.productSlider {

  .splide__track {
    padding-bottom: 4rem;
  }

  .splide__pagination__page {
    transform: scale(1.4);
    border: 1px solid #009EE0;
    background: #FFF;


    &.is-active {
      background: #009EE0;
    }
  }
}

