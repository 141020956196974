$colorCompanyBlueLight: #71cef3;
$colorCompanyBlueDark: #004987;
$colorTileLight: #daf2fe;
$colorTileDark: #004986;
$colorCompanyTextDark: #2a2d34;

.company-header {

    background: #DAF0FD;

    &__visual {
        // max-width: 1090px;
        margin: 0 auto;        


        position: relative;
        &-img {
            width: 100%;
            display: block;
        }
    }

    &__headline {
        position: absolute;
        bottom: 1.6rem;
        right: 3.2rem;
        text-align: right;

        @media (max-width: 720px) {
            position: static;
            background: #FFF;
            padding: 1rem;
        }        

        h2, h3 {
            text-transform: uppercase;
            font-size: 3.2rem;
            color: $colorCompanyBlueLight;
            margin: 0;
            
        }   
        h3 {
            font-weight: 700;
            color: $colorCompanyBlueDark;
        }
    }

    &--high {

       

        .company-header__visual {
            // max-width: 1090px;
            margin: 0 auto;
        }
    }
}

.company-headline {
    text-align: center;
    padding: 6.4rem 0 3.2rem;

    h2 {
        font-weight: 600;
        color: $colorCompanyBlueDark;
        font-size: 2.8rem;
        
    }
}

.company-tiles {
    display: flex;
    justify-content: center;

    @media (max-width: 1120px) {
        display: block;
    }
}


.tile {

    margin: 1.2rem;
    margin-top: 2.4rem;
    margin-bottom: 0;
    text-decoration: none;

    @media (max-width: 1120px) {
        display: block;
        max-width: 355px;
        margin: 0 auto 2.4rem;
    }

    .btn {
        text-align: center;
        cursor: pointer;
        font-size: 1.5em;
        text-transform: uppercase;
        font-family: FFFagoWebPro;
        color: #FFF;
        background: #1dafec;
        border: 2px solid #1dafec;
        padding: 1rem 4rem .8rem;
        display: inline-block;
        box-sizing: border-box;
        border-radius: .4rem;
        position: relative;   
        text-decoration: none;   
        min-width: none;
        svg {
            position: relative;
            bottom: -4px;
        }
    }

    &--wide {


        max-width: 71rem;
        position: relative;
        background: $colorTileLight;
        flex: 2;

        @media (max-width: 1120px) {

            max-width: 355px;

        }        

        .tile {
            overflow: hidden;

            &__visual {
                width: 50%;
                @media (max-width: 1120px) {
                    width: 100%;
                }                  

                &-img {
                    width: 100%;
                    display: block;
                }
            }

            &__info {
                position: absolute;
                width: 50%;
                right: 0;
                bottom: 0;
                top: 0;
                box-sizing: border-box;
                padding: 2.4rem;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;


                @media (max-width: 1120px) {
                    position: relative;
                    width: 100%;
                }                

                h3 {
                    font-weight: 700;
                    margin: 0;
                    color: $colorTileDark;
                    text-transform: uppercase;
                }

                p {
                    color: $colorCompanyTextDark;
                    text-align: center;
                    max-width: 100%;
                }
            }
        }

        &.tile--imageLast {
            
            .tile {
                &__visual {
                    float: right;
                }

                &__info {
                    left: 0;
                    right: auto;   
                    
                    p {
                        max-width: 100%;
                    }
                }                
            }

            

        }
    }

    &--full {


        max-width: 71rem;
        position: relative;
        background: $colorTileLight;
        display: flex;

        .tile {
            overflow: hidden;

            &__visual {
                width: 33.33333%;

                &-img {
                    width: 100%;
                    display: block;
                }
            }

            &__info {
                position: relative;;
                width: 66.666666%;
                right: 0;
                bottom: 0;
                top: 0;
                box-sizing: border-box;
                padding: 2.4rem 3.2rem;
                display: flex;
                align-items: flex-start;
                justify-content: center;
                flex-direction: column;

                h3 {
                    font-weight: 700;
                    margin: 0;
                    color: $colorTileDark;
                    text-transform: uppercase;
                }

                p {
                    color: $colorCompanyTextDark;
                    text-align: left;
                }
            }
        }

        &.tile--imageLast {
            
            .tile {
                &__visual {
                    float: right;
                    order: 2;
                }

                &__info {
                    left: 0;
                    right: auto;  
                    order: 1;  
                }                
            }

            

        }
    }    

    &--narrow {

        position: relative;
        background: $colorTileLight;
        flex: 1;
        max-width: 35.6rem;
        height: 35.6rem;

        .tile {

            &__visual {
                height: 50%;

                &-img {
                    width: 100%;
                    display: block;
                }
            }

            &__info {
                position: absolute;;
                top: 50%;
                right: 0;
                left: 0;
                bottom: 0;
                box-sizing: border-box;
                padding: 2.4rem;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;




            }

            &__headline {
                font-weight: 700;
                margin: 0;
                color: $colorTileDark;
                text-transform: uppercase;
            }            

            &__text {
                color: $colorCompanyTextDark;
                text-align: center;
                max-width: 100%;
            }            
        }
    }  
    
    &--dark {
        background: $colorTileDark;  
        
        .tile__info p, .tile__info h3 {
            color: #FFF;
        }
        
    }
}

.company-text {

    margin: 4rem auto 0;

    @media (max-width: 740px) {

        margin: 4rem 2rem 0;
    }    
       

    &--headlineAbove {
        max-width: 64rem;
        text-align: center;
    }

    &--headlineLeft {
        display: flex;
        max-width: 64rem;
        justify-content: center;

        @media (max-width: 740px) {

            display: block;
        }
     

        .company-text__headline {
            margin-right: 3.2rem;
            text-align: right;
            max-width: 18rem;
            min-width: 18rem;     
            
            @media (max-width: 740px) {

                text-align: center;
                max-width: none;
                margin-right: 0;
            }                
        
        }

        .company-text__body {
            margin-top: 0;

            @media (max-width: 740px) {

                text-align: center;;
            }            

        }        
        
    }

    &--headlineLeftSmall {
        display: flex;
        max-width: 64rem;
        justify-content: center;

        .company-text__headline {
            margin-right: 3.2rem;
            max-width: 18rem;
            min-width: 18rem;            

            text-align: right;

            @media (max-width: 740px) {

                text-align: center;
                max-width: none;
                margin-right: 0;
            }                

            &-sub {
                margin-top: 0;
                font-size: 1.8rem;
                line-height: 1;
            }
        }

        .company-text__body {
            margin-top: 0;

        }        
        
    }    

    &__headline {
        text-transform: uppercase;
    }

    &__headline-main {
        color: $colorCompanyBlueLight;
        font-size: 2.8rem;
        font-weight: normal;
        margin: 0;
    }

    &__headline-sub {
        color: $colorCompanyBlueDark;
        font-size: 2.4rem;     
        font-weight: 600;;   
        margin: 1.2rem 0 0;
        line-height: 2.8rem;

        @media (max-width: 740px) {

            margin-bottom: 1.2rem;
        }            
    } 

    &__body {

        margin-top: 3.2rem;

        > p {
            color: $colorCompanyTextDark;
            font-size: 1.4rem;
            line-height: 2.4rem;
            margin: .8rem 0 0;

            &:first-child {
                margin-top: 0;
            }
        }        
    }


    & + .company-tiles {
        margin-top: 3.2rem;
    }
}

.company-image {

    margin-top: 4.8rem;
    
    &__img {
        max-height: 400px;
        width: 100%;
        display: block;
    }

    &:last-child {
        margin-bottom: -4.8rem;        
    }
}

.company-blog-entry {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;


    &__body {

        margin-top: 3.15rem;

        & > * {
            margin-bottom: 0;
            margin-top: 1.05rem
        }

        color: $colorCompanyTextDark;

        h1 {
            color: $colorCompanyBlueLight;
            font-size: 3.5rem;
            

            & + p {
                margin-top: 2.625rem;
                font-size: 1.8rem;
                line-height: 2.625rem;

                & + h2 {
                    margin-top: 3.15rem;
                }
            }
        }

        p {
            font-size: 1.6rem;
            line-height: 2.1rem;
        }


        h2 {
            font-size: 2rem;
            letter-spacing: 0;
            font-weight: 400;
            margin-top: 2.1rem;
            color: $colorCompanyBlueDark;
        }

        h3 {
            font-weight: 400;
            margin-top: 2.1rem;
            color: $colorCompanyBlueDark;            
        }
    }



}

.company-blog-previews {
    text-align: center;
    background: $colLightBlue;
    padding: 6.4rem 3.2rem;
    margin-top: 6.4rem;

    &__entries {
        display: flex;
        margin-top: 3.2rem;
        justify-content: center;
        flex-wrap: wrap;
    }


    &:last-child {
        margin-bottom: -4.8rem;
    }
}

.company-blog-preview {
    
    display: block;
    background: #FFF;
    width: 32rem;
    height: 32rem;   
    text-decoration: none;

    text-align: left;
    color: $colorCompanyTextDark;
    margin: 0 3.2rem;
    margin-top: 3.2rem;
    
    overflow: hidden;
    position: relative;
    
    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 5rem;

        background: rgb(255,255,255);
        background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);        
    }

    & > * {
        text-decoration: none;
        margin: 0;
    }


    &:hover {
        color: $colorCompanyBlueDark;
    }

    &__inner {
        padding: 2.4rem;        
    }

    &__visual {
        width: 100%;
        display: block;
    }

    &__date {
        font-size: 1.6rem;
        opacity: .75;

        &-icon {
            height: 1.25rem;
            opacity: .5;
            margin-right: .25rem;
        }
    }

    &__headline {
        text-transform: uppercase;
        font-size: 2.4rem;
        line-height: 1;
        margin-top: 1.6rem;
        margin-bottom: 0;
    }

    p {
        line-height: 1.5;
        margin-top: 1.2rem;
        font-size: 1.4rem;
    }
}


.company-form {
    max-width: 960px;
    margin: 0 auto;
}

.company-text--headlineLeft + .company-text--headlineLeftSmall {
    border-top: 1px solid #DDD;
    padding-top: 4rem;
}

.companyPage .articles {
    max-width: 800px;
    margin: 0 auto;
    padding: 0 2rem;
}