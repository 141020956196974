.downloadTable {
    table-layout: auto;
    
    h3 {
        margin: 2rem 0 1rem;
    }
}

#sheetSearch, #videoSearch {
    display: block;
    padding: .8rem 1.2rem .8rem;
    padding-left: 4rem;
    font-family: "ff-dax-web-pro";
    border-radius: 0;
    font-size: 1.6rem;
    box-sizing: border-box;
    text-overflow: ellipsis;
    background: #a0def7; 
    position: relative;
    width: 49%;
    box-sizing: border-box;
    background-color: #e5e5e6;
    border-radius: .4rem;
    border: none;
    
    color: #555;
    @media (max-width: 560px){
        font-size: 1.6rem;
        
    }     
    &:focus {
        outline: none;
    }
}

#sheetSearchForm, #videoSearchForm {
    position: relative;
    left: 25%;
    width: 75%; 
     margin: 5rem 0 4rem;
    @media (max-width: 560px){
        left: 0%;
        width: 100%; 
        
    }     
    
    label {
        color: #0077BF;
        font-size: 1.2rem;
        padding-bottom: 0.4rem;
        font-weight: 600;
        display: block;
    } 
    
    .fa-search {
        position: absolute;
        left: 1rem;
        font-size: 1.4rem;
        color: #0080C6;
        bottom: .8rem;
        color: #6b6c72;
        font-size: 2rem;
        z-index: 2;
       
             
    } 
       
} 

#videoSearchForm {
    left: 0;
}

.dataSheets {
    table {
        border: none;
    }
    tr {
        background: #fff;
    }
    th {
        
        width: 25%;
        text-align: right;
        color: #0077BF;
        font-size: 1.8rem;
        font-weight: normal;

        
    }
    td {

        width: 75%;
    }    
    
    th, td {
        vertical-align: top;
        line-height: 1.6rem;
        padding: 0;
        //border-bottom: 1px solid darken(rgba(185, 229, 249, 1.0), 10%);
        @media (max-width: 560px){
            display: block;
            width: auto;
            text-align: left;
            padding: 0;
            
        }         
    }
    
    th {
        padding-top: 2.5rem;
        padding-right: 2rem;
        @media (max-width: 560px){
            padding-top: 2rem;
        
        }         
    }
    
    .downloadSheet {
        display: flex;
        flex-wrap: wrap;
        
        display: -ms-flexbox;
        -ms-flex-wrap: wrap;
        margin-right:-2rem;
        
        @media (max-width: 720px){
            display: block;
            margin: 0;
        }          
    }
    
    .downloadSheetInner {
       
        width: 50%;
        @media (max-width: 720px){
            width: auto;
        }             

        
        
    }
    
    .downloads {
        margin: 0.5rem 0 1rem;
        padding: 0.5rem 0;
        background: lighten(#b9e5f9, 5%);

        padding: 1rem;
        margin: 1.5rem;
        margin-bottom: 0;
        margin-left: 0;
        
        //border-bottom: 1px solid darken(rgba(185, 229, 249, 1.0), 10%);
        @media (max-width: 560px){
            margin: 1rem 0;
            
        }          

       
        

        li {
            margin-bottom: .5rem;
            white-space: nowrap;
            
            padding-left: 3rem;
            position: relative;
            
            &:last-child {
                margin: 0;
            }
            .flag {
                position: absolute;
                top: .2rem;
                left: 0;

            }
        }  
        
        a {
            color: #004987;
            font-weight: 600;
            overflow: hidden;
            text-overflow: ellipsis;
            display: block;
        }      
        
    }
    

}

table {
    border: 1px solid rgba(185, 229, 249, 1.0);
    border-collapse: collapse;
    width: 100%;
    margin: 0 0 3rem;
    table-layout: fixed;
    
    th, td {
        padding: .6rem;
        font-size: 1.4rem;
        color: #6B6C72;   
        text-align: left; 
        vertical-align: center;
        
        &.revision {
            width: 8rem;
            text-align: center;
        }
        
        &.type {
            width: 2rem;
        }        
        
        &.flags {
            width: 2rem;
        } 
        
        &.download {
            width: 7rem;
        } 
        &.date {
            width: 11.2rem;
        }      

        
    }
    th {
        font-weight: 700;   
    }  
    tr {
        background: rgba(185, 229, 249, 1.0);
    }
    tr:nth-child(odd) {
        background: #FFF;
    }
    
    thead {
        tr:first-child {
            background: rgba(185, 229, 249, 1.0);
            
        }
        th {

            vertical-align: bottom;
        }
    }    
    
    td, th {
        line-height: 1;
        
        a {
            text-decoration: none;
            color: #6B6C72; 
        } 
        

                
        &.type {
            text-transform: uppercase;
        } 
        
        &.download {
            
            i {
                
                font-size: 1.4rem;
            }            
            
            a {
                display: block;
                white-space: nowrap;
                padding: .6rem .8rem .4rem;
                background: #20BEF0;
                color: #FFF;     
                font-size: 1.2rem;  
                cursor: pointer;       
                text-align: center;  
            }

        }
        
        .downloads {
            list-style: none;
            padding: 0;
            margin: 0;
        }
      

        
    }

}


.technical-data {

    border: 0;
    margin: 0;

    th {
        font-weight: bold;
    }

    tr, tr:nth-child(odd) {
        background: #FFF;
        &:last-child {
           td {
               border-bottom: 0;
           }
        }
    }

    td, th {
        border-bottom: 1px solid #b9e5f9;
        width: 50%;
        padding: 1rem 0;
    }

    td:nth-child(2), th:nth-child(2), td:nth-child(3), th:nth-child(3) {
        text-align: right;
        width: 25%;
    }

}