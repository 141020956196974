@supports(display: grid) {
    #page {
        grid-area: main;
        display: grid;
        grid-gap: 2rem;
        grid-row-gap: 0;

        grid-template-columns:  2fr 2fr 1fr 3fr ;

        grid-template-rows: auto minmax(min-content, 20px) auto auto;

        grid-template-areas:
            "header header header header header"
            ". breadcrumbs breadcrumbs breadcrumbs breadcrumbs"
            "main main main main main"
            "teaser teaser teaser teaser teaser"
            "footer footer footer footer footer"
        ;
    }
    .landingPageSimple {
        .mainVisual {
            margin-top: -2rem;        
        }
    }

    .breadcrumbs {
        padding-left: 0 !important;
        grid-area: breadcrumbs;
    }

    .teaser {
        box-sizing: border-box;
        grid-area: teaser;
        padding-bottom: 4rem;
    }

    footer {
        grid-area: footer;
    }
    
    aside {
        grid-area: aside;
        width: auto !important;
    }
    main {
        grid-area: main;
        width: auto !important;
    }
    
    .catalogCategory, .catalogArticleGroup {
        #page {
            grid-template-areas:
            "header header header header header"
            ". breadcrumbs breadcrumbs breadcrumbs breadcrumbs"
            "aside main main main main" 
            "teaser teaser teaser teaser teaser"
            "footer footer footer footer footer"
        ;                
        
        }    

    }

    .catalogArticle {
        #page {
            grid-template-areas:
                    "header header header header"
                    ". breadcrumbs breadcrumbs breadcrumbs"
                    "aside aside main main"
                    "teaser teaser teaser teaser"
                    "footer footer footer footer"
        ;
            @media (max-width: 930px) {
                grid-template-areas:
                        "header header header header"
                        ". breadcrumbs breadcrumbs breadcrumbs"
                        "aside aside aside main"
                        "teaser teaser teaser teaser"
                        "footer footer footer footer";
            }
            @media (max-width: 640px) {
                grid-template-areas:
                        "header header header header"
                        "breadcrumbs breadcrumbs breadcrumbs breadcrumbs"
                        "aside aside aside aside"
                        "main main main main"
                        "teaser teaser teaser teaser"
                        "footer footer footer footer";
            }
        }

    }
    .depotContact {
        #page {
            grid-template-areas:
                    "header header header header"
                    ". breadcrumbs breadcrumbs breadcrumbs"
                    "main main main aside"
                    "teaser teaser teaser teaser"
                    "footer footer footer footer"
        ;
            @media (max-width: 930px) {
                grid-template-areas:
                        "header header header header"
                        ". breadcrumbs breadcrumbs breadcrumbs"
                        "main main main aside"
                        "teaser teaser teaser teaser"
                        "footer footer footer footer";
            }
            @media (max-width: 640px) {
                grid-template-areas:
                        "header header header header"
                        "breadcrumbs breadcrumbs breadcrumbs breadcrumbs"
                        "aside aside aside aside"
                        "main main main main"
                        "teaser teaser teaser teaser"
                        "footer footer footer footer";
            }
        }        
    }
    
    .catalogSearch {
        #page {
            grid-template-areas:
                    "header header header header"
                    ". breadcrumbs breadcrumbs breadcrumbs"
                    "main main main main"
                    "teaser teaser teaser teaser"
                    "footer footer footer footer"
        ;
        }
    }

    .home {
        #page {


            grid-row-gap: 0;
            grid-template-areas:
                    "header header header header"
                    "main main main main"
                    "teaser teaser teaser teaser"
                    "footer footer footer footer"
        ;
        }

 
    }

    .companyPage, .companyBlogEntry, .landingPageRonden, .landingPageRondenOverview {

        header {
            @media (max-width: 1120px) {

                padding-right: 2rem;
                .serviceMenu #search {
                    max-width: 180px;
                    overflow: hidden;
                }                  
            }    
                           
        }

        padding: 0;

        #page {
            
        //     grid-row-gap: 0;
        //     grid-template-areas:
        //             "header header header header"
        //             "main main main main"
        //             "footer footer footer footer"
        // ;
            max-width: none;
            display: block;

            header, footer {
                max-width: 1090px;
                margin: 0 auto;
                grid-area: none;
                
                

            }
            header {
                padding-left: 0;
                position: relative;
                nav {
                    padding-left: 22.5%;
                    box-sizing: border-box;
                    
                }
                
            }

            footer {
                margin-top: 4.8rem;
            }

      
        }

        .breadcrumbs{

            display: none;
        }

        main {
            padding-top: 0;
        }

        .teaser {
            display: none;;
        }

        footer {
            margin-top: 2.4rem;
        }
    }    
    
    .basicPage {
        #page {
            grid-row-gap: 0;
            grid-template-areas:
                    "header header header header"
                    ". breadcrumbs breadcrumbs breadcrumbs"
                    "main main main main"
                    "teaser teaser teaser teaser"
                    "footer footer footer footer"
        ;
        }

    }    

    .teaserBox-01 {
        display: grid;
        grid-gap: 2rem;
        grid-template-areas:
                "article01 article02 highlight"
                "article03 article04 highlight"
    ;
        @media (max-width: 640px) {
            grid-gap: 1rem;
            grid-template-areas:
                    "article01 highlight"
                    "article02 highlight"
                    "article03 article04"
        ;
        }
        padding: 4rem 0;




        article {
            width: 100%;
            float: none;
            padding-bottom: 0;
            height: 31vw;
            margin: 0;

            @media (min-width: 1090px) {
                height: 335px;
            }
            @media (max-width: 640px) {
                height: 46vw;
            }

        }

        article:first-child {
            grid-area: article01;
        }
        article:nth-child(2) {
            grid-area: article02;
        }
        article:nth-child(3) {
            grid-area: article03;
        }
        article:nth-child(4) {
            grid-area: article04;
        }

        .highlightProduct {

            grid-area: highlight;


            article {
                height: 100%;
                width: 100%;
                margin: 0;
                float: none;
                background: #FFF;

                h3 {
                    background: transparent;
                }
                .article-image {
                    //mix-blend-mode: multiply;
                    left: 0;
                    right:0;
                    background-size: contain;
                }
            }


        }




    }

    .catalogCategory, .catalogArticleGroup {
        #page {
            @media (max-width: 640px) {
                grid-template-columns: 1fr 1fr;
                grid-template-areas:
                        "header header"
                        "breadcrumbs breadcrumbs"
                        "aside aside"
                        "main main "
                        "teaser teaser"
                        "footer footer";

            }

        }
    }


}

.home, .catalogSelection {
    main {
    
        padding-top: 0;
    }
}

.catalogSelection {
    main {
    
        padding-top: 0;
        margin-top: -2rem;
        
 
    }
}