.overlay #page {
    pointer-events: none;
    

}

.landingPageSimple, .landingPageCart, .landingPageProductSelection {
    .breadcrumbs {
        display: none;
    }
    main {
        padding-top: 0;
    }   

    .mainVisualBg {
        position: absolute;
        background: #e3f3fd;
        left: 0;
        right: 0;
        z-index: 1;
        height: 0;
    }
    
    .mainVisualDescriptionBg {
        position: absolute;
        background: #972234;
        left: 0;
        right: 0;
        z-index: 1;
        height: 0;
    }    

    
    .mainVisual {
        position: relative;
        z-index: 2;
        margin-bottom: 0;
        
        @media(max-width: 640px) {                
            padding: 2rem 0;
        }         
        img {
            display: block;
            image-rendering: auto;    
            height: auto;       
        }
        
        
    } 
    .landingPage {
        .form {
            &:after {
                display: none;
                
            }  
        }           
    }
    
    .InputfieldGroup_submit {
        flex-direction: row !important;
        & > *:first-child {
            align-self: flex-end;
        }
    }

}

.landingPage  {
    
    
    padding: 0;
    
    #page {
        
        @media(max-width: 375px) {                
                &:before {
                    height: 92px;
                }
                #logo {
                    height: 92px;                    
                }
                header {
                    height: 92px;
                }
        }         
    }
    
    h1 {
        left: 3rem;
        @media(max-width: 375px) {
                left: 1.5rem;
                img {
                    height: 140px;
                }

        }  
    }    
    
    main {
        padding-top: 0;
    }
    
    h2 {
        font-size: 2.8rem;
                
        @media(max-width: 640px) {
                font-size: 2.4rem;

        }  
        @media(max-width: 360px) {
                font-size: 1.8rem;

        }                   
    }
    
    .cms-text {
        text-transform: uppercase;
        letter-spacing: .1rem;
        margin: 3rem 0 3rem;
        p {
            font-size: 1.6rem;
            line-height: 1.6;
            margin: 3rem 0;

            @media(max-width: 640px) {
                    font-size: 1.4rem;
    
            }             
            
            
        }
        
    }
    .cms-list ul {
        text-transform: uppercase;
        list-style: none;
        margin: 0;

        letter-spacing: .1rem;
        text-align: left;
        padding: 0 20%;
        @media(max-width: 640px) {
                padding: 0 1rem;

        }            
        
        li {
            font-size: 1.6rem;
            @media(max-width: 640px) {
                    font-size: 1.4rem;
    
            }                
            
            position: relative;
            padding-left: 3rem;
            
            margin-bottom: 1.25rem;
            &:before {
                content: ' ';
                
                position: absolute;
                top: .2rem;
                left: 0;                    
                
                width: 1.5rem;
                height: 1.5rem;
                border-radius: .75rem;
                border: 2px solid #FFF;
                box-sizing: border-box;
               
                
            }
        }
    }  
    
    .bgOverlay {
        display: none;
        background: rgba(255, 255, 255, .75);
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 9;
    }  
    
    #page {
        max-width: 920px;
        position: relative;
        display: block;
        
        #logo {
            display: block;
        }
        
    }
     
    .landingElement {
        font-family: FFFagoWebPro; 
        font-weight: normal; 
        font-style: normal; 
        letter-spacing: .025rem;
        
    }
    .mainVisual {
        img {
            width: 100%;
        }
    }
    
    .mainVisualDescription {
        background: #009569;
        padding: 6rem 4rem;
        position: relative;
        margin-bottom: 8rem;
        @media(max-width: 420px) {
            padding: 4rem 3rem;
        }

        &:after {
            position: absolute;
            content: '';
            width: 0;
            height: 0;
            border-left: 8rem solid transparent;
            border-right: 8rem solid transparent;
            border-bottom: 8rem solid #972234;
            transform: rotate(270deg);
            left: 5%;
            top: -4rem;
            z-index: 2;      
            @media(max-width: 640px) {                
                border-left: 4rem solid transparent;
                border-right: 4rem solid transparent;
                border-bottom: 4rem solid #972234;                
                top: -2rem;
            }             
        }

        p {
            font-size: 3rem;
            text-align: center;
            color: #FFF;  
            margin: 0;
            position: relative;
            z-index: 3;
            
            @media(max-width: 640px) {                
                br {
                    display: none;
                }
            }  
            
            @media(max-width: 420px) {
                font-size: 2.8rem;
            }                      
            
            &:last-child {
                padding-top: 1.5rem;
            }          
        }
        
    }
    
    .questionCircles {
        position: relative;
        margin-top: 2rem;
        opacity: 0;
        transition: opacity .5s ease-in;
        
        &.visible {
            opacity: 1;
            
        }
        ul {
            padding:5%;   
            list-style: none;  
            position: relative;
            overflow: hidden;
            box-sizing: border-box;
                      
            li {
             
                float: left;
                padding-top: 2rem;     
                width: 33.33333%;                  
                
            }         
        }
        .slideContainer {
            transition: transform 1s ease-in-out, opacity .25s ease-in-out;
            opacity: 1;
            top: 0;
            position: relative;
            padding-bottom: 12rem;
        }
        
        [aria-hidden=true] .slideContainer  {
            opacity: 0;
        }
        
        
        .question {
            
            opacity: .25;
            
            cursor: pointer;
            display: block;
            border: 1px solid #919195;
            margin: 0 auto;
            
            box-sizing: border-box;
            
            width: 10rem;       
            height: 10rem;
            border-radius: 5rem;         
            
            background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.95 20.03"><defs><style>.a{fill:rgba(145, 145, 149, 1);}</style></defs><title>questionMark</title><path class="a" d="M5.91,8.3c.95-1,1.78-1.93,1.78-3.46,0-1.9-1.23-2.85-3-2.85A8.56,8.56,0,0,0,.86,3.06L0,1.32A10.1,10.1,0,0,1,4.78,0C7.59,0,10,1.5,10,4.69,10,6.92,8.79,8.15,7.5,9.55,5.94,11.21,5.33,12.07,5.33,14H3.4C3.4,11.27,4.26,10.11,5.91,8.3Zm-2.69,9.1H5.54V20H3.22Z"/></svg>');
            
            background-repeat: no-repeat;
            background-size: 3.5rem;
            background-position: center center;
            
            transition: background-color linear 1s, border-color linear 1s, opacity linear 1s;
            
            @media(max-width: 640px) {
                width: 8rem;       
                height: 8rem;
                border-radius: 4rem;
                background-size: 2.5rem;
            }               
                   
        }
        .slideContainer.active {
            
            transform: scale(1.1)  translateY(8px);
            transition: transform 1s ease-in-out;
            //top: 22px !important;            
            
            .question{            
                opacity: 1;
                border-color: #1DAFEC;
                background-color: #1DAFEC;
                background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.95 20.03"><defs><style>.a{fill:rgba(255, 255, 255, 1);}</style></defs><title>questionMark</title><path class="a" d="M5.91,8.3c.95-1,1.78-1.93,1.78-3.46,0-1.9-1.23-2.85-3-2.85A8.56,8.56,0,0,0,.86,3.06L0,1.32A10.1,10.1,0,0,1,4.78,0C7.59,0,10,1.5,10,4.69,10,6.92,8.79,8.15,7.5,9.55,5.94,11.21,5.33,12.07,5.33,14H3.4C3.4,11.27,4.26,10.11,5.91,8.3Zm-2.69,9.1H5.54V20H3.22Z"/></svg>');                
            }   
            
            p {
                opacity: 1;
                
                //transition: opacity linear .25s .25s, top linear .25s .25s;                
            }              
              
        }   

        p {
            opacity: 0;
            transition: opacity linear .5s;

            text-align: center;
            text-transform: uppercase;
            color: #00AEEF;
            font-size: 2rem;
            
            position: absolute;
            left: -66.6666%;
            right: -66.6666%;            

            
        }        
        
    }
    
    .collapsedInfo {
        text-align: center;
        background: #1DAFEC;
        color: #FFF;
        padding: 4rem;
        @media(max-width: 640px) {
                padding: 2rem; 
                

        }            
        
        h2 {
            position: relative;
            cursor: pointer;
            font-size: 4rem;
            
            &:before {
                content: '';
                position: absolute;  
                bottom: 13rem;
                border-top: 1px solid #FFF;
                left: 40%;
                right: 40%;
                
            }
            &:after {
                -webkit-backface-visibility: hidden;
                content: ' ';
                margin: 3rem auto 0;
                
                border: 1px solid #fff;
                border-radius: 5rem;
                box-sizing: border-box;
                display: block;
                
                width: 10rem;       
                height: 10rem;    
                background-repeat: no-repeat;            
                background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.92 14.71"><defs><style>.a{fill:rgba(255, 255, 255, 1);}</style></defs><title>Unbenannt-2</title><polyline class="a" points="21.29 14.39 21.29 14.07 1.26 14.07 10.96 1.06 21.03 14.58 21.29 14.39 21.29 14.07 21.29 14.39 21.54 14.2 10.96 0 0 14.71 21.92 14.71 21.54 14.2 21.29 14.39"/></svg>'); 
                background-size: 6rem;
                background-position: center 2rem;    
                
                transform: rotate(180deg);   
                transition: transform ease-in .25s, background-image .5s ease-in;    
/*
                @media(max-width: 640px) {
                    width: (10rem * 2/3);       
                    height: (10rem * 2/3); 
                    border-radius: (5rem * 2/3); 
                    background-size: (6rem * 2/3);
                    background-position: center (2rem * 2/3);                          
                        
        
                }  
*/          
                         
            }
            &:hover {
                &:after {
                    background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.92 14.71"><defs><style>.a{fill:rgba(255,255,255,.4);.line{fill:rgba(255,255,255,1);</style></defs><title>untitled</title><polygon class="a" points="0 14.71 10.96 0 21.92 14.71 0 14.71"/><polyline fill="white" points="21.29 14.39 21.29 14.07 1.26 14.07 10.96 1.06 21.03 14.58 21.29 14.39 21.29 14.07 21.29 14.39 21.54 14.2 10.96 0 0 14.71 21.92 14.71 21.54 14.2 21.29 14.39"/></svg>');
                }
            }

        }
        &.open {
            h2 {
                &:after {
                    transform: rotate(0deg);
                }
            }      
            
        }
        .collapsed {
            display: none;
        }
        

    }
    .overlayInfo {
        display: flex;
        justify-content: space-between;
        
        display: -ms-flexbox;
        -ms-flex-pack: justify;
                
        padding: 4rem 2rem; 
        @media(max-width: 640px) {
            display: block;
        }        
        
        
        .infoBox {
            flex: 1;
            -ms-flex: 1;
            @media(max-width: 640px) {
                width: auto;
            }            
            margin: 2rem;
            box-sizing: border-box;
            pointer-events: all;
            & > a {
                position: relative;
                display: block;
                text-align: center;
                color: #FFF;
                background-image: url(../../images/infoBoxBg.jpg);
                background-size: cover;
                background-position: center 50%;
                padding: 2rem;
                text-decoration: none;

                
                &:after {
                    opacity: 0;
                    content: ' ';
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    background: #1FBAEF svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.94 25"><defs><style>.a{fill:rgba(255, 255, 255, 1);}</style></defs><title>+ Icon</title><rect class="a" y="12.09" width="24.94" height="0.99"/><rect class="a" x="11.89" width="0.99" height="25"/><rect class="a" x="11.89" y="0.49" width="0.99" height="8.06"/><rect class="a" x="11.89" y="14.78" width="0.99" height="8"/></svg>') no-repeat;  
                    background-size: 5rem;
                    background-position: center center;   
                    transition: opacity ease-in .25s;                                   
                }
                
                &:hover {
                    &:after {
                        opacity: .825;
                    }
                    

                }
            }

        }   
        .infoOverlay {
            display: none;
            position: fixed;
            height: auto;
            top: 0;
            margin: 0 auto;
            box-sizing: border-box;
            z-index: 10; 
            background-image: url(../../images/infoBoxBg.jpg);
            background-size: cover; 
            padding: 4rem;  
            
            color: #FFF;    
            text-align: center;
            pointer-events: all;
            @media(max-width: 640px) {
                    padding: 4rem 2rem 2rem; 
                    
    
            }                
            

            
            h2:after {
                content: '';
                display: block;
                margin: 0 auto;
                width: 20%;
                border-top: 1px solid #FFF;
    
            }    
            
            .close {
                cursor: pointer;
                position: absolute;
                right: 4rem;
                top: 0;
                
                width: 4rem;
                height: 4rem;
                background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 6.81 7.13"><defs><style>.a{fill:rgba(255, 255, 255, 1);}</style></defs><title>X Icon</title><path class="a" d="M.43,5.67,2.35,3.42l-1.62-2A3.81,3.81,0,0,1,.39.92.84.84,0,0,1,.28.51.44.44,0,0,1,.49.16.91.91,0,0,1,1,0,.85.85,0,0,1,1.6.17a4.46,4.46,0,0,1,.56.64L3.45,2.48,4.83.81,5.12.45A2,2,0,0,1,5.35.2.77.77,0,0,1,5.6,0a.94.94,0,0,1,.32,0,.87.87,0,0,1,.54.16.46.46,0,0,1,.21.37,1.56,1.56,0,0,1-.45.85l-1.7,2L6.34,5.67a3.6,3.6,0,0,1,.36.49.74.74,0,0,1,.11.37.5.5,0,0,1-.1.3.66.66,0,0,1-.28.22A1,1,0,0,1,6,7.13.94.94,0,0,1,5.6,7a.86.86,0,0,1-.28-.2l-.39-.48L3.42,4.46l-1.61,2-.27.33-.19.18a.86.86,0,0,1-.26.14,1.13,1.13,0,0,1-.36.05A.85.85,0,0,1,.21,7,.53.53,0,0,1,0,6.52a1.45,1.45,0,0,1,.43-.85"/></svg>');
                background-position: center center;
                background-repeat: no-repeat;
                background-size: 1.5rem;
                
                border: 2px solid #FFF;
                transition: background-color ease-in 0.25s, background-image ease-in 0.25s;
                
                &:hover {
                    background-color: #FFF;
                    background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 6.81 7.13"><defs><style>.a{fill:rgba(0, 73, 135, 1);}</style></defs><title>X Icon</title><path class="a" d="M.43,5.67,2.35,3.42l-1.62-2A3.81,3.81,0,0,1,.39.92.84.84,0,0,1,.28.51.44.44,0,0,1,.49.16.91.91,0,0,1,1,0,.85.85,0,0,1,1.6.17a4.46,4.46,0,0,1,.56.64L3.45,2.48,4.83.81,5.12.45A2,2,0,0,1,5.35.2.77.77,0,0,1,5.6,0a.94.94,0,0,1,.32,0,.87.87,0,0,1,.54.16.46.46,0,0,1,.21.37,1.56,1.56,0,0,1-.45.85l-1.7,2L6.34,5.67a3.6,3.6,0,0,1,.36.49.74.74,0,0,1,.11.37.5.5,0,0,1-.1.3.66.66,0,0,1-.28.22A1,1,0,0,1,6,7.13.94.94,0,0,1,5.6,7a.86.86,0,0,1-.28-.2l-.39-.48L3.42,4.46l-1.61,2-.27.33-.19.18a.86.86,0,0,1-.26.14,1.13,1.13,0,0,1-.36.05A.85.85,0,0,1,.21,7,.53.53,0,0,1,0,6.52a1.45,1.45,0,0,1,.43-.85"/></svg>');
                }
                
            }          
  
                         
        }               
    }
    
    .infoBoxes {
        h2 {
            text-align: center;
            color: #231F20;
            margin-bottom: 3rem;
            &:after {
                content: '';
                display: block;
                margin: 0 auto;
                width: 20%;
                border-top: 1px solid #231F20;
                
            }            
        }
        .shopInfo {
            h3 {
                //font-family: FFFagoWebPro;
            }
            
        }
    }
    
    .map {
        h2 {
            background: rgba(29, 174, 235, 1.0);
            color: #FFF;
            text-align: center;
            padding: 2rem 0 1.4rem;
            margin: 0;
            margin-top: 5rem;
        }
        img {
            display: block;
            width: 100%;
        }
        
        .mapContainer {
            position: relative;
            

            

        }
        
        .logoImage {
            cursor: pointer;
            position: absolute;
            bottom: 10%;
            top: 45%;
            left: 42%;
            width: 15%;
            @media(max-width: 720px) {
                top: 20%;
                left: 38%;
                width: 25%;
            }                         
        }
        
        address {
            
            
            opacity: 0;
            position: absolute;
            right: 2rem;
            bottom: 2rem;
            
            background: #FFF;
            padding: 1rem 2rem 1.8rem;
            display: inline-block;
            color: #636466;
            box-shadow: 0 0 3rem rgba(0, 0, 0, .35);
            border-radius: 1.5rem;
            text-align: center;
            font-style: normal;
            font-size: 1.6rem;
            
            transition: opacity .5s linear;
            
            @media(max-width: 420px) {
                left: 5rem;
                right: 5rem;
            }               
            
            
            b {
                display: block;
                margin: 1rem 0 0.2rem;
                text-transform: uppercase;
                color: #000;
                font-size: 2rem;
            }
        }
    }
    .form {
        
        &:after {
            content: '';
            display: block;
            margin: 4rem auto;
            width: 20%;
            border-top: 1px solid #231F20;
            
        }           

        h2 {
            margin-top: 6rem;
            text-align: center;
            color: #231F20;
            margin-bottom: 3rem;
            &:after {
                content: '';
                display: block;
                margin: 0 auto;
                width: 20%;
                border-top: 1px solid #231F20;
                margin-top: 1rem;
                
            }   
        }
        
        
        .error, .success {
            text-align: center;
            margin: 4rem 6rem;  
            p {
                font-size: 1.8rem;
                color: #231F20; 
            }
             
        }


        .Inputfields {
            display: flex;
            flex: 1;
            flex-wrap: wrap;
            
            display: -ms-flexbox;
            -ms-flex: 1;
            -ms-flex-wrap: wrap; 
            
            margin: 0 4rem;   
            
            @media(max-width: 560px) {
                display: block;
                margin: 0;
            }                       
            
            
           
            
            .InputfieldHeader {
                font-size: 1.4rem;
                color: #636466;
                margin-left: 1rem;
                margin-bottom: .5rem;
                display: block;
                
            }    
            
            .info {
                font-size: 1.4rem;
                color: #636466;
                margin-left: 1rem;
    
                display: block;                
            } 
            
            .InputfieldGroup {

                padding: 1rem;
                width: 50%; 
                @media(max-width: 560px) {
                    width: 100%; 
                }                   
                box-sizing: border-box;
                
                display: flex;
                flex-wrap: wrap;
                flex-direction: column;
                justify-content: space-between;
                
                display: -ms-flexbox;
                -ms-flex: 1;
                -ms-flex-wrap: wrap; 
                -ms-flex-direction: column;
                -ms-flex-pack: justify;     
                
                
            }
            
            .InputfieldFormBuilderFile {
                
                
                
                .uploadButton {

                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    text-align: center;
                    //overflow: hidden;
                    display: inline-block;
                    cursor: pointer;
                    font-size: 1.6em;
                    font-family: FFFagoWebPro;                    
                    color: rgba(29, 175, 236, 1.0);
                    background: #FFF;
                    border: 2px solid rgb(172, 224, 248);;
                    padding: 1rem 1rem .8rem; 
                    display: block;
                    box-sizing: border-box;
                    border-radius: .4rem 0 0 .4rem;   
                    line-height: 1;  
                    position: relative;
                    margin-right: 3rem;
                    
                    margin-bottom: .5rem;
                    
                    transition: border-color .25s ease-in, background-color .25s ease-in;
                    
                    input {
                        position: fixed;
                        top: -100%;
                    }       
                    

                }
                
                .hasFile {
                    background: rgba(29, 175, 236, 1.0);
                    color: #FFF;
                    border-color: rgba(29, 175, 236, 1.0);
                }
                
                .uploadRow {
                    position: relative;
                    
                    &:hover {
                        .uploadButton {
                            border-color: rgba(29, 175, 236, 1.0);
                        }
                        
                        .addFile {
                            border-color: rgba(29, 175, 236, 1.0);
                        }
                    }
                }
                
                .addFile {
                    
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    width: 3rem;
                    border: 2px solid rgb(172, 224, 248);;
                    
                    border-radius: 0 .4rem .4rem 0; 
                    
                    background-color: #FFF;
                    
                    background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 784 980"><defs><style>.a{fill:rgba(29, 175, 236, 1.0);}</style></defs><title>63a66f8aaf9b7b0f88ea565e0bcb9e46</title><path class="a" d="M500,10c13.8,0,25.4,4.7,34.8,14.2l343,343c9.4,9.4,14.2,21.1,14.2,34.8,0,14-4.7,25.7-14,35s-21,14-35,14c-13.8,0-25.4-4.7-34.8-14.2L549,177.3V941a49,49,0,0,1-98,0V177.3L191.8,436.8c-9.4,9.4-21.1,14.2-34.8,14.2-14,0-25.7-4.7-35-14s-14-21-14-35c0-13.8,4.7-25.4,14.2-34.8l343-343c9.4-9.5,21-14.2,34.8-14.2Z" transform="translate(-108 -10)"/></svg>');                         
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: 1.25rem;  
                    
                    transition: border-color .25s ease-in,  background-color .25s ease-in;
                    
                    cursor: pointer;                      
                }
                
                .removeFile {
                    background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 6.81 7.13"><defs><style>.a{fill:rgba(255, 255, 255, 1.0);}</style></defs><title>X Icon</title><path class="a" d="M.43,5.67,2.35,3.42l-1.62-2A3.81,3.81,0,0,1,.39.92.84.84,0,0,1,.28.51.44.44,0,0,1,.49.16.91.91,0,0,1,1,0,.85.85,0,0,1,1.6.17a4.46,4.46,0,0,1,.56.64L3.45,2.48,4.83.81,5.12.45A2,2,0,0,1,5.35.2.77.77,0,0,1,5.6,0a.94.94,0,0,1,.32,0,.87.87,0,0,1,.54.16.46.46,0,0,1,.21.37,1.56,1.56,0,0,1-.45.85l-1.7,2L6.34,5.67a3.6,3.6,0,0,1,.36.49.74.74,0,0,1,.11.37.5.5,0,0,1-.1.3.66.66,0,0,1-.28.22A1,1,0,0,1,6,7.13.94.94,0,0,1,5.6,7a.86.86,0,0,1-.28-.2l-.39-.48L3.42,4.46l-1.61,2-.27.33-.19.18a.86.86,0,0,1-.26.14,1.13,1.13,0,0,1-.36.05A.85.85,0,0,1,.21,7,.53.53,0,0,1,0,6.52a1.45,1.45,0,0,1,.43-.85"/></svg>');  
                    background-size: 1rem;  
                    background-color: rgba(29, 175, 236, 1.0);   
                    border-color: rgba(29, 175, 236, 1.0);                                    
                }                
            }
            
            .InputfieldText, .InputfieldEmail, .InputfieldTextarea {
                padding: 1rem;
                width: 50%;
                @media(max-width: 560px) {
                    width: 100% !important; 
                }                   

                box-sizing: border-box;
                
                

                input, textarea {
                    background-color: rgba(229, 229, 230, 1.0);
                    font-size: 1.6rem;
                    font-family: FFFagoWebPro;
                    color: #000;
                    border-radius: .4rem;
                    padding: 1rem 1rem .8rem;                        
                    border: 2px solid rgba(229, 229, 230, 1.0);
                    width: 100%;
                    box-sizing: border-box;
                    display: block;
                }
                


                
            } 
            .InputError {
                input, textarea {
                    border-color: rgba(241, 143, 146, 1.0);
                    background-color: rgba(241, 143, 146, 1.0);
                    color: #FFF;
                    transition: background-color ease-out .25s, border-color ease-out .25s;
                }                    
            }             
            

        }

    }    
}

.InputfieldSubmit {


    position: relative;
    input {
        position: absolute;
        left: 0;
        top: 1.25rem;
    }

    p {
        padding-left: 0;
    }

}


[name*='_submit'] {
    text-align: center;
    min-width: 17rem;
    cursor: pointer;
    font-size: 1.6em;
    font-family: FFFagoWebPro;                    
    color: #FFF;
    background: rgba(29, 175, 236, 1.0);
    border: 2px solid rgba(29, 175, 236, 1.0);
    padding: 1rem 4rem .8rem; 
    display: inline-block;
    box-sizing: border-box;
    border-radius: .4rem;
    position: relative;
    &:after{
        position: absolute;
        content: "";
        width: 0;
        height: 0;
        right: 1rem;
        top: .9rem;
        border-top: .75rem solid transparent;
        border-bottom: .75rem solid transparent;
        border-left: 1.1rem solid #FFF;
    }                 
}

.cartTableWrap {
	padding: 0 5rem;
    @media(max-width: 560px) {                
       padding: 0;
    } 		

}

.cartTable {
	border: none;
	width: 100%;
	


	table-layout: fixed;
	box-sizing: border-box;
	
	
	
	th, td {
		
		vertical-align: top;
		padding-top: .2rem;
		padding-right: 1rem;
        @media(max-width: 560px) {                
           padding-right: .25rem;
        } 			
	}
	&__qty {
		width: 5%;
        @media(max-width: 900px) {                
            width: 10%;
        } 		
	}	
	&__sku {
		width: 10%;
        @media(max-width: 900px) {                
            width: 20%;
        } 			
	}
	
	&__name {
	
	}	
	
	&__price {
		width: 15%;
        @media(max-width: 900px) {                
            display: none;
        }  		
	}
	
	&__discount {
		width:10%;
        @media(max-width: 900px) {                
            display: none;
        }  			
	}
	
	&__discountPrice {
		width: 10%;
        @media(max-width: 900px) {                
            display: none;
        }  			
	}
	
	&__finalPrice {
		width: 10%;
		text-align: right;
        @media(max-width: 900px) {                
            width: 20%;
        } 			
	}
	
	thead {
		tr {
			background: none !important;
			
		}	
		th {
			font-size: 1.2rem;
			font-weight: 300;
			font-family: ff-dax-web-pro-condensed;
			font-weight: 300;
			text-transform: uppercase;
			vertical-align: bottom;
		}	
	
			
	}

	tbody {
		.cartTable {
			&__qty {
				span {
					padding: .4rem .5rem .3rem;
					color: #FFF;
					background: rgba(29, 175, 236, 1.0);
					border-radius: 2px;
						
				}
				
			}
			
			&__sku {
		
				input {
					background: #e5e5e6;
					border: none;
					border-radius: 2px;
					padding: .4rem 1rem;
					display: block;
					box-sizing: border-box;
					width: 100%;
					position: relative;
					top: -4px;
                    @media(max-width: 560px) {                
                       background: transparent;
                       padding: .4rem 0;
                                    
                    } 						
				}
			}
			
			&__name {
			
				span {
					color: #000;
					white-space: nowrap;
					font-size: 1.4rem;
					line-height: 1.8rem;
					display: block;
					position: relative;
					top: -2px;	
			        @media(max-width: 900px) {                
			           white-space: normal;
			        } 			
                    @media(max-width: 560px) {                
                        font-size: 1.2rem;
                        top: -4px;	
                        
                                    
                    }			        							
					
				}
			}	
			
			&__price {
				color: #000;
			}
			
			&__discount {
				color: #000;
				width: 5rem;				font-weight: bold;
				color: rgba(29, 175, 236, 1.0);
				
			}
			
			&__discountPrice {
				color: #000;

			}		
			
			&__finalPrice {
				color: 000;
				text-align: right;
				b {
					font-weight: bold;
					display: inline-block;
					padding: .4rem .5rem .3rem;
					color: #FFF;
					background: rgba(29, 175, 236, 1.0);
					border-radius: 2px;		
					position: relative;
					top: -4px;			
				}
			}	
		}	
		
	}

}

.landingPageCart .mainVisualDescriptionBg {
	border-bottom: rgba(157, 212, 231, 1.0) 16rem solid;
	
}

.landingPageCart .overview {
	height: 16rem;
	padding: 3rem 4rem;
	box-sizing: border-box;
	color: #FFF;
	position: relative;
	z-index: 2;
	margin-top: -8rem;
	text-align: center;
	
	
	a {
		background: #FFF;
		border: 2px solid rgba(56, 170, 218, 1.0);
		border-radius: 1rem;
		display: inline-block;
		padding: 3rem 2rem 2.4rem 12rem;
		text-transform: uppercase;
		text-decoration: none;
		color: rgba(71, 72, 71, 1.0);
		font-size: 2.4rem;
		font-weight: 300;
		background: #fff url(../../images/fernglas.png) no-repeat;
		background-size: 100px;
		background-position: 10px center ;
		
	}
}

.landingPageCart .mainVisualDescription p {
	margin-bottom: .8rem;
}

.landingPageCart .mainVisualDescription p:last-child {
	font-size: 1.6rem;
	margin: 0;
}

.landingPageCart {
    .landingElement.text {
        max-width: 580px;
        margin: 0 auto;
        padding: 3rem 0rem;
        text-align: center;
        p {
            color: rgba(71, 72, 71, 1.0);
            font-size: 2rem;
            margin: 0;
        }
    }
}