body.dev #page {
    &::after {
        content: 'DEV';
        position: fixed;
        left: 0;
        top: 0;
        width: 5rem;
        height: 5rem;
        color: #FFF;
        background: red;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 2rem;
        z-index: 10;
    }
}

header {
    
    grid-area: header;
    
    height: 162px;
    position: relative;
    z-index: 3;
    
    padding-left: 25%;

    
    @media(max-width: 720px) {
        height:105px;
        padding-left: 0;

    }

    #logo {
        @media(max-width: 720px) {
            height: 105px;
            h1 {
                z-index: 4;
            }
        }
        max-width: 20px;

    }

    .mobile-toggle {
        display: none;
        position: absolute;
        right: 0;
        padding: 0;
        top: -3rem;
        font-size: 1.6rem;
        text-decoration: none;

        @media (max-width: 720px) {
            display: block;

        }
    }
    
    .serviceMenu {
        display: flex;
        display: -ms-flexbox;
        
        justify-content: flex-end;
        -ms-flex-pack: end; 
        
        padding-top: 2.4rem;
        margin-bottom: 6.4em;  
        
        height: 3rem;        
        
        @media(max-width: 720px) {
            padding-top: 0;
            margin-bottom: 66px;
        }  
        
   
        
        & > ul {
            display: flex;
            display: -ms-flexbox;  

            text-align: right;     
            list-style: none;
            margin: 0;
            padding: 0;
            @media(max-width: 720px) {
                display: none;
            }                    
            
            li {
                margin-right: 3rem;
                
                a {
                    text-decoration: none;
                    font-size: 1.2rem;
                    &.current {
                        font-weight: 700;
                    }
                }
            }
        }
        
        .nav {

            &--subnav {
                &:hover {
                    .nav__subnav {
                        display: block;
                    }
                }
            }

            &__subnav {
                display: none;
                list-style: none;
                background: #FFF;
                background: rgba(255, 255, 255, 0.9);
                border-radius: .2rem;
                box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
                padding: .9rem;
                margin: 0;
                position: relative;
                z-index: 10;
                
                li {
                    margin: 0;
                    white-space: nowrap;
                }

            }
        }

        #search {
            position: relative;
            z-index: 5;
            min-width: 40%;

            
            @media (max-width: 960px) {
                top: 1.2rem;
                min-width: 40%;
            }
            @media (max-width: 720px) {
                top: 1.2rem;
                min-width: 50%;
            }
            @media (max-width: 340px) {
                top: 1.2rem;
                min-width: 45%;
            }
        }        
    }
    
    nav {
        position: relative;
        z-index: 3;
        height: 0;
        @extend .tabNav;



        & > ul {

            margin: 0;
            
            
            
            @media (max-width: 720px) {

                display: none;

                background: #71CEF3;
                margin-top: 0;

                margin-left: -1rem;
                margin-right: -1rem;
                padding-top: 4rem;



                &.open {

                }
            }

            & > li {
                margin-right: 1.5rem;
                
                @media (max-width: 720px) {
                    display: block;
                    border-bottom: 1px solid #231F20;
                    background: #ACE0F8;


                    margin-right: 0;

                    a {
                        border-radius: 0;
                    }

                    a:after {
                        display: none;
                    }
                }
                
                & > a {
                    color: #0080C6;
                    background: transparent;
                    padding: 1.4rem 3.2rem 1.6rem;
                    @media (max-width: 920px) {
                        padding: 1rem 2rem 1.6rem;
                    }                     
                }
            }
            & > .current > a:after {
                content: '';
                position: absolute;
                left: -1.2rem;
                right: -1.2rem;
                height: 1px;
                bottom: -1px;
                background: $colBgDarkShadow;
                transition: opacity .25s linear;
                opacity: 1;
            } 
            & > .showsSubNav > a:after {
                opacity: 0;
            }              
           
                         
            
            .subNav {
                @media (max-width: 720px) { 
                    display: none !important;
                }
                
                opacity: 0;
                display: none;
                
                position: absolute;
                z-index: 4;
                left: 0;
                right: 0;                
                
                background: rgba(255,255,255,.9);
                border-radius: .2rem;
                box-shadow: 0 0 1rem rgba(0,0,0,.2);
            
                padding: 1.8rem 1.8rem;

                
                transition: top .25s linear, opacity .25s linear;
                
                font-size: 1.4rem;
                line-height: 1.2;
                list-style: none;

                text-transform: uppercase;
                column-count: 2;
                column-gap: 2.4rem; 
                         
                li {
                    margin: 0 0 1.2rem;
                    padding-left: 1.2rem;
                    background: url(../../images/arrow.svg) no-repeat;
                    background-size: 7px auto;
                    background-position: 0 2px;
                    transition: background-position linear .25s;
                }
                
                li:hover {
                    background-position: 2px 2px;
                }
        
                a {
                    color: #0065A4;
                    text-decoration: none;
                }
        
                .currentSub a{
                    font-weight: 700;
                }
                
            }
              
            .subNavOpen {
                display: block;
                opacity: 1;
;

            }    
            
            .subNavTransition {
                opacity: 0;

                display: block;

            }                    

        }


    }
    h1 {
        position: absolute;
        z-index: 3;
        margin: 0;
        left: 0;
    
        img {
            display: block;
            height: 242px;
            cursor: pointer;
            margin-top: -7px;
            @media(max-width: 720px) {
                height:160px;
            }
    
        }
    }

}


.nav--mobile {
    @media(min-width: 720px) {
        display: none !important;
    }  
}