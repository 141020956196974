.table-toggle {
    position: relative;
    cursor: pointer;
    text-decoration: underline;
    border: 1px solid #b9e5f9;
    text-decoration: none;
    color: #6B6C72;
    padding: 10px 6px;
    margin-top: 0 !important;
    margin-bottom: -1px !important;
    font-size: 14px;
    display: block;
    font-weight: bold;
    position: relative;

    span {
        display: block;
        white-space: nowrap;
        padding: .6rem .8rem .4rem;
        background: #20BEF0;
        color: #FFF;
        font-size: 1.2rem;
        cursor: pointer;
        text-align: center;
        position: absolute;
        right: 6px;
        top: 6px;
        width: 54px;
        line-height: 1;    
        
        i {
            font-size: 1.4rem;
        }
    }


    & + table {
        display: none;
    }

    &--open {
        background: #20BEF0;
        color: #FFF;
        i {
            transform: rotate(90deg);
        }
    }
}