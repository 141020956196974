.breadcrumbs {
    padding-left: 25%;
}
 
.teaser {
    clear: both;
    
}

footer {
    clear: both;
}

.catalogCategory, .catalogArticleGroup {
    

       
    aside {
        float: left;
        width: 25%
    }
    
    main {
        float: right;
        width: 75%;
    }
    

}

.catalogArticle {
    aside {
        float: left;
        width: 50%;
        margin-right: 0;
        box-sizing: border-box;
        padding-right: 1.25%;
    }
    
    main {
        padding-left: 1.25%;
        float: right;
        width: 50%;
        margin-right: 0;
        box-sizing: border-box;
    }
        
}

.depotContact {
    aside {
        float: right;
        width: 50%;
        margin-right: 0;
        box-sizing: border-box;
        padding-right: 0;
    }
    
    main {
        padding-left: 0;
        float: left;
        width: 50%;
        margin-right: 0;
        box-sizing: border-box;
    }
        
}