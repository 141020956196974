@use '../base/colors' as *;

div[data-service] {
    width: 100%;
}

.splide__slide {
    --im-btn-color: $colBgDark;

    div[data-service] .cll .c-bg {

        opacity: .75;

        &::before {
            background: none;
        }
        
    }
}
