$colBgLight:            #71CEF3;    //blauer Header BG
$colBgDark:             rgba(13, 102, 162, 1.0);    //dunkelblauer Header Highlight
$colBgDarkTransparent:  rgba(13, 102, 162, .2);    //dunkelblauer Header Highlight
$colBgDarkShadow:       #231F20;
$colFontBgDark:         #D9E8F1;    
$colHighlight:          #1AAB8D;    //grüne Produktboxen
$colFont:               #0077BF;    //Schriftfarbe
$colDarkBlue:           #0d66a2;
$colDarkText:           #2a2d34;
$colLightBlue:          #cfeefb
