$textColorGreen: #19AB8D;
$lightBlueBg: #DAF0FD;

.landingPageRonden, .landingPageRondenOverview {

    .ronden-section-headline {
        text-align: center;
        margin: 0;
        color: #0f4b83;
    }
    
    .teaser {
        display: block;
        max-width: 1090px;
        margin-left: auto;
        margin-right: auto;
    }


}

.landingPageRonden #page footer, .landingPageRondenOverview #page footer {
    margin-top: 0 !important;
}

.landingPageRondenOverview {

    .section__inner {
        max-width: 1090px;
        margin: auto;
    }

    .ronden-header {
        img {
            position: relative;
            width: 100%;
        }
    }

    .ronden-menu {
        margin: 0;
    }

    .articles {
        @media (max-width: 590px) {
            padding: 0 1rem;
        }        
    }

    article {
        width: 23.5%;
        margin-right: 2%;

        @media (max-width: 590px) {
            width: 48%;
            margin-right: 4%;
        }
    }

    article:nth-child(3n) {
        margin-right: 2%;

        @media (max-width: 590px) {
            margin-right: 4%;
        }        

    }
     

    article:nth-child(4n) {
        margin-right: 0;
    }    
    
    @media (max-width: 590px) {
        article:nth-child(2n) {
            margin-right: 0;
        }    
    }    

    .section-start {
        color: #0077BF;
        margin-bottom: 1.6rem;

        &::after {
            display: none;
        }


    }

    .landing-dark {
        &::after {
            display: none;
        }
    }

    .toggleIcon {
        margin-bottom: 1.6rem;
    }



    .ronden-links__headline {
        border: 0;
    }

    .ronden-links {
        margin-top: 1.6rem;
    }

    .toggleIconLupe {
        

        width: 64px;
        height: 64px;
        display: block;
        margin: 0 auto;
        text-align: center;
        background: url(../../images/lupe.png) no-repeat;
        background-size: 100%;
      
    }



}

.landing-toggle {
    &:hover {
        background: darken(#dcf0fb, 5%);
        cursor: pointer;
        .toggleIconLupe {
    
            background-position: 0 100%;
        }        
    }

    &.open:hover {
        background: #dcf0fb;
    }

    &.open .toggleIconLupe {
        background: url(../../images/close.png) no-repeat;
        background-size: contain;;
    }    
}
 
.ronden-details {
    max-width: 490px;
    margin: 3.2rem auto 0;
    display: block;
}

.ronden-header {
    background: #DAF0FD;
    overflow: hidden;

    img {
        position: absolute;
        top: 0;
    }


    &__inner {
        max-width: 1080px;
        position: relative;
        margin: auto;
        padding: 0 .5rem;

        @media (max-width: 1100px) {
            padding: 0 2rem;
        }        
    }

    &__title {
        position: relative;
        padding: 5rem 0 0;

    }

    &__headline {
        text-transform: none;
        font-family: "ff-dax-web-pro", sans-serif;
        font-weight: 800;
        color: $textColorGreen;
        font-size: 12rem;
        margin: 0;
        line-height: 1;
        @media (max-width: 550px) {
            font-size: 8rem;
        }            

    }
    &__subheadline {
        font-family: "ff-dax-web-pro", sans-serif;
        font-weight: 600;
        color: $textColorGreen;
        font-size: 3rem;
        padding-left: 8rem;
        margin: 1rem 0 0;
        line-height: 1;
        
        .highlight {
            color: rgba(196, 32, 108, 1.0);
            font-weight: 600;
        }           

        @media (max-width: 1100px) {
            padding-left: 0;
        }          

    }

    &__text {
        position: relative;
        color: #1a171b;
        font-size: 1.5rem;
        line-height: 1.4;
    }

    &__link {
        position: relative;
        margin: 2rem 0 0;
        text-align: center;
    }
    
}

.ronden-menu {
    position: relative;

    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
            align-items: stretch;
    
    margin: 3rem 0 0;
    padding: 0;
    list-style: none;
    background: lighten(desaturate(#0f4b83, 25%), 10%);
    
    @media (max-width: 1100px) {
        margin: 3rem -2rem 0;
    }       

    @media (max-width: 490px) {
        margin: 3rem -2rem 0;
        flex-wrap: wrap;
    }       

    & > li {

        width: 19.5%;
        box-sizing: border-box;
        position: relative;
        border-right: 1px solid #FFF;
        
        background: #0f4b83;

        @media (max-width: 490px) {
            position: relative;
            bottom: -1px;             
            border: 0;
            width: 50%;
            border-bottom: 1px solid #FFF;

            &:nth-child(odd), &:last-child {
                border-right: 1px solid #FFF !important;
            }
        }           
        
        & > a {
            padding: 1rem;
            display: block;
            
            color: #FFF;
            font-size: 1.6rem;
            text-decoration: none;
            

        }
        &:hover {
           background: lighten(#0f4b83, 10%);
        }        
        
        &.active {
            background: #009ee0; 
            &:hover {
                background: #009ee0;
            }                            
        }
        
        &:last-child {

            border-right: 0;
            .info-box {
                left: auto;
                right: 0;
            }
        }
        @media (max-width: 720px) {
            &:nth-child(3), &:nth-child(4) {
                .info-box {
                    left: auto;
                    right: -154.8076923%;
                }                
            }    
        }        
        

      
        
    }

    
    
}

.ronden-products {
    overflow: auto;
    margin: 4rem auto 0;    
}

.products-matrix {
    table-layout: fixed;
    border-spacing: .5rem;
    border-collapse: separate;
    background:transparent;
    width: auto;
    margin: 2rem auto 0;
    border: none;

    &__row-label {
        color: #0061a1;
        font-size: 1.8rem;
        vertical-align: bottom;
        padding: 0;
        text-align: right;

        small {
            display: inline-block;
            position: relative;
            bottom: .125rem;
            font-size: 1.2rem;
            font-weight: normal;
            
        }

        div {
            position: relative;
            bottom: 1rem;
            font-weight: bold;
        }

        span {
            display: block;
            border-bottom: 1px solid #006198;
            border-left: 1px solid #006198;
            height: 1rem;
            padding: .25rem  0 .25rem .25rem;
            padding-left: .5rem;
            

    
        }
    }
   
    &__column-label {
        font-weight: 300;
        text-align: center;
        font-size: 1.25rem;
        padding-left: 0;
        padding-right: 0;

        div {
            position: relative;
            top: 0rem;

            &::after {
                content: '';
                position: absolute;
                width: 1rem;
                border-top: 1px solid #706e5e;
                top: -8.5px;
                left: -6px;
            }
        }

        span {
            display: block;
            border-top: 1px solid #706e5e;
            border-left: 1px solid #FFF;
            height: 1rem;
            padding-top: .75rem;
        }   
        
        &--start {

            border-left: 10px solid #FFF;

            span {
                border-left: 1px solid #706e5e;
                left: -20px;
            }

            div {

                &::after {
                    display: none;
                }
            }
            
        }

    }

    tr {
        background:transparent !important;
    }

    th {

        width: 3rem;
        white-space: nowrap;
        background:transparent;

    }

    td {
        width: 3em;
        border-spacing: .5rem;
        border-collapse: separate;  
        background: transparent;  
        padding: 0; 
        
        img {
            height: 214px;
            display: block;
            margin-top: 2rem;
        }

        span {
            display: block;
            width: 4rem;
            height: 3rem;            
            background:rgba(237, 247, 252, 1.0);
        }

        a {
            overflow: hidden;
            display: block;
            width: 4rem;
            height: 3rem;
            background: #ddf0fa;
            text-indent: 100%;
            white-space: nowrap;
            position: relative;
            transition: background-color .25s linear;

            &::after {
                content: '';
                position: absolute;
                top: .65rem;
                left: 50%;
                margin-left: -.9rem;
                background: #ddf0fa;
                width: 1.25rem;
                height: 1.25rem;
                border: .25rem solid #0070b8;
                border-radius: 100%;
                box-shadow: 0 0 0px 2px #ddf0fa;
                opacity: 0;
                transition: opacity .25s linear;

            }            

            &:hover {
                background: #0070b8;
                &::after {
                    opacity: 1;
                }
            }
        }
    }

    &__cell--section-start {
        border-left: 10px solid #FFF;
    }
}

.ronden-infobox {



    &__inner {
        display: flex;
        margin: 4rem auto 0;
        max-width: 1080px;;        

        @media (max-width: 640px) {
            display: block;
        }          

        & > div {
            width: 50%;
            flex: 1;
            border-right: 2px solid #FFF;

            @media (max-width: 640px) {
                border: 0;
                width: auto;
                border-bottom: 2px solid #FFF;
            }                   
            

            &:last-child {
                border-left: 2px solid #FFF;
                border-right: 0;
            }

            
        }
    }

    
    [class*=content] {
        border-top: 2px solid #FFF;
        background: rgba(219, 240, 233, 1.0);
        padding: 1rem;
        display: none; 

        ul {
            list-style: none;;
        }
        li {
            color: #1a171b;
            margin-top: .25rem;

            &::before {
                content: "\2022";
                color: #0f4b83;
                font-weight: bold;
                display: inline-block;
                width: 1.5rem;
                margin-left: -1.5rem;
                font-size: 2rem;
                line-height: 1;
                position: relative;
                bottom: -3px;
              }            
        }
    }

    [class*=toggle] {
        display: block;
        border: 0;
        appearance: none;
        background: rgba(196, 235, 247, 1.0);
        width: 100%;
        box-sizing: border-box;
        padding: 4rem 1rem;
        text-align: center;
        cursor: pointer;

        &:hover, &:focus {
            background: darken(rgba(196, 235, 247, 1.0), 5%);
            outline: 0;


        }

        &:hover {
            .cls-2, .cls-3, .cls-4 {
                stroke: #60B3E6;
                
            }
            .cls-1 {
                fill: #60B3E6;
            }
        }
        

        h3 {
            color: #0f4b83;
            font-family: FFFagoWebPro;
            font-size: 3rem;
            margin: 0 0 1rem;
            
        }

        svg {
            width: 6rem;
            height: 6rem;
            
        }
    }
}

.ronden-links {
    margin-top: 8rem;
    text-align: center;

    a{
        display: inline-block;
        color: #FFF;
        text-decoration: none;
        padding: 4rem;
        font-size: 1.8rem;
        

        &:hover {
            img {
                transform: scale(1.1);
            }
            
        }
    }

    img {
        transition: transform linear .25s;
        width: 8rem;
        display: block;
        margin: 0 auto 1rem;
    }

    &__headline {
        border-top: 1px solid #bfc0c1;
        padding-top: 4rem;
        max-width: 960px;
        margin: auto !important;
    }

    &__inner {
        background: url(../../images/ronden-links-bg.png) no-repeat;
        background-size: cover;
        padding: 4rem 2rem;
        max-width: 1080px;
        margin: 2rem auto 0;
    }
}

.ronden-sintern {
    padding: 8rem 2rem;
    text-align: center;
    


    
}

.sintern-button {
    appearance: none;
    background: none;
    border: 0;
    padding: .8rem 2rem;
    border-radius: 1rem;
    text-transform: uppercase;
    cursor: pointer;
    font-family: "ff-dax-web-pro", sans-serif;
    font-weight: 500;
    font-size: 3rem;
    color: #0077BF;
    letter-spacing: .125rem;

    img {
        display: block;
        max-width: 160px;
        margin: 1rem auto;
        transition: transform .125s linear;
    }  


    &:hover, &:focus {
        
        outline: none;
        color: darken(#0077BF, 10%);

        img {
            transform: scale(1.05);
        }

        
    }

    &:hover + svg  #fire-large {
        fill: #007AA8 !important;
        opacity: .75;
    }

    & + svg  #fire-large {
        fill: #009ee0 !important;
        opacity: .5;
        transition: opacity linear .25s, fill linear .25s;
    }


}

#modal-sintern, #modal-composition {

    .iziModal-header.iziModal-noSubtitle {
        min-height: 2rem;
        box-shadow: none;
    }

    .modal {
        &__inner {
            padding: 2rem;
            @media (max-width: 760px) {
                padding: 0em;
            }  
        }

        &__header {
            h2 {
                text-align: left;
                padding: 0 2rem 2rem;
                @media (max-width: 760px) {
                    padding: 2rem;
                }                  
            }
            
            img {
                display: block;
                max-width: 100%;
                
            }
        }

        &__content {
            padding: 2rem 2rem 0;

            & > img {
                max-width: 100%;
                padding: 1rem 1rem 2rem;
                box-sizing: border-box;
            }


            h3 {
                margin: 2rem 0 0;
                .number {
                    margin-right: .5rem;
                }
            }

            p {
                color: #333;
            }

            .number {
                display: inline-block;
                background: #0f4b83;
                width: 1.8rem;
                height: 1.8rem;
                color: #FFF;
                text-align: center;
                font-size: 1.3rem;
                line-height: 1.5;
                border-radius: 100%;
                font-family: FFFagoWebPro;
                position: relative;
                bottom: 3px;
            }  
            
            td > .number {
                bottom: 0;
            }

            table {
                margin: 2rem 0 0;
                width: 66.66666%;
                border: 1px solid #DFDFDF;
                @media (max-width: 760px) {
                    width: 100%;
                }                   
            }

            table tr{
                background: #FFF;
                
            }      

            table tr:first-child {
                background: #b9e5f9;
            }    
                 
            th, td {
                text-align: center;
                font-weight: normal;
                vertical-align: top;
                color: #333;

                border-right: 1px solid #BBB;
                
            }


            .sintern-info {
                margin-top: 3rem;;

                &__table {
                    display: flex;
                    align-items: flex-end;

                    @media (max-width: 760px) {
                        display: block;
                    }                      
                }
     
                &__graph {
                    width: 33.33333%;
                    @media (max-width: 760px) {
                        width: auto;
                    }                     
                    img {
                        display: block;
                        height: 80px;
                        width: auto;
                        padding-left: 1rem;
                        @media (max-width: 760px) {
                            width: auto;
                            padding-left: 0rem;
                            padding-top: 1rem;
                        }  
                    }
                }

                &__info {
                    width: 75%;
                    margin-top: 4rem;;
                    @media (max-width: 760px) {
                        width: auto;
                    }                        
                }
            }            
        }

    }
}

.ronden-infos__buttons {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.sintern-link {
    padding-left: 2rem;
    text-decoration: none;
    display: inline-block;
    margin-right: 20%;
    white-space: nowrap;

    img {
        width: 4rem;
        height: auto;
        display: block;

        margin-bottom: .5rem;


    }
}