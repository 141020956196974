.bauer-prose {

    margin: 0 auto;
    padding-bottom: 10rem;
    max-width: 92rem;
    text-align: center;

    &__logo {
        width: 100%;
        margin: 0 auto;
        display: block;
        margin-top: 6rem;
    }

    &__greeting {
        font-size: 5rem !important;
        font-weight: bold;
        position: relative;
        color: #e0003a !important;
    }

    p {
        font-size: 3rem;
        line-height: 1.5;
        margin-top: 6rem;

        a {
            font-weight: bold;
            text-decoration-thickness: .25rem;
        }

        b {
            font-size: 9rem;
            font-weight: bold;
            line-height: 1;            
        }
    }



}