.depot-list {
    margin: 3.2rem 0;
    border: 1px solid #B9E5F9;

}

.depots {
    list-style: none;
    margin: 0;
    padding: 0;
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;
    
    display: -ms-flexbox;
    -ms-flex-positive: 1;
    -ms-flex-wrap: wrap;

    li:before {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        content: '';
        z-index: 0;
    }


    li:nth-child(4n-2):before, li:nth-child(4n-3):before {
        background: #B9E5F9;
    }
    @media (max-width: 1260px) {
        li:nth-child(1n):before {
            background: #FFF;
        }
        li:nth-child(2n - 1):before {
            background: #B9E5F9;
        }

    }


    li {
        font-size: 1.4rem;
        color: #6B6C72;
        
        display: flex;
        align-items: center;
        justify-content: space-between;
        
        display: -ms-flexbox;
        -ms-flex-align: center;
        -ms-flex-pack: justify;
        
        padding: 0 .6rem;
        box-sizing: border-box;
        position: relative;
        width: 50%;
        @media (max-width: 1260px) {
            width: 100%;
        }


    }

    span {
        padding: 1rem 2rem .8rem;
        position: relative;
        white-space: nowrap;
        min-width: 120px;
    }

    a {
        background: #20BEF0;
        color: #FFF;
        display: inline-block;
        padding: .6rem .8rem .4rem;
        text-decoration: none;
        position: relative;
        font-size: 1.2rem;
        white-space: nowrap;


    }
}