footer {
    
    height: 162px;

    &:after {
        content: '';
        position: absolute;
        z-index: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 162px;
        background: $colBgLight;
    }
    
    nav {
        position: relative;
        z-index: 1;
        padding: 2.4rem 0;
        font-size: 1.4rem;
        text-align: right;
        
        a {
            text-decoration: none;
            font-size: 1.2rem;
            margin-left: 3rem;
            &.current {
                font-weight: 700;
            }
	        @media (max-width: 680px) {
	            &:after {
		            content: '';
		            display: block;
	            }
	    
	        }               
        }
        
        .copyright {
            font-size: 1.2rem;
            float: left;
            font-weight: 600;
        }
    }

    .social {
        display: flex;
        position: relative;
        z-index: 1;
        background: #71CEF3;
        margin: 0 -1rem;

        align-items: center;
    }
}
