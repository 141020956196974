//Flaggen von https://github.com/lipis/flag-icon-css/tree/master/flags/4x3

.flag {
    display: inline-block;
    width: 2rem;
    height: 1.5rem;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    //box-shadow: 0 0 .5rem rgba(0,0,0,.2);
}

//de
.country-2, .country-de {
    background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" height="480" width="640" viewBox="0 0 640 480"><path fill="#ffce00" d="M0 320h640v160.002H0z"/><path d="M0 0h640v160H0z"/><path fill="#d00" d="M0 160h640v160H0z"/></svg>');
                        
}

//uk
.country-3, .country-gb {
    background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 641.71 481.28"><defs><style>.a{fill:none;}.b{clip-path:url(#a);}.c{fill:#006;}.d{fill:#fff;}.e{fill:#c00;}</style><clipPath id="a"><rect class="a" width="641.71" height="481.28"/></clipPath></defs><title>Download (2)</title><g class="b"><path class="c" d="M-160.43,0H802.15V481.29H-160.43Z"/><path class="d" d="M-160.43,0V53.81l855,427.48H802.15V427.48L-52.79,0ZM802.15,0V53.81L-52.79,481.29H-160.43V427.48L694.53,0Z"/><path class="d" d="M240.64,0V481.29H401.07V0ZM-160.43,160.43V320.86H802.15V160.43Z"/><path class="e" d="M-160.43,192.52v96.26H802.15V192.52ZM272.73,0V481.29H369V0ZM-160.43,481.29,160.43,320.86h71.78L-88.69,481.29Zm0-481.29L160.43,160.43H88.69L-160.43,35.87Zm570,160.43L730.4,0h71.74L481.29,160.43H409.57Zm392.6,320.86L481.29,320.86H553L802.15,445.42Z"/></g></svg>');
}


//de+uk
.country-4 {

    background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 735.51 482.13"><defs><style>.a{fill:none;}.b{clip-path:url(#a);}.c{fill:#006;}.d{fill:#fff;}.e{fill:#c00;}.f{fill:#ffce00;}.g{fill:#d00;}</style><clipPath id="a" transform="translate(1.5 2.13)"><rect class="a" x="259" y="195" width="380" height="285"/></clipPath></defs><title>Download</title><g class="b"><path class="c" d="M164,195H734V480H164Z" transform="translate(1.5 2.13)"/><path class="d" d="M164,195v31.86L670.28,480H734V448.14L227.73,195Zm570,0v31.86L227.73,480H164V448.14L670.28,195Z" transform="translate(1.5 2.13)"/><path class="d" d="M401.5,195V480h95V195ZM164,290v95H734V290Z" transform="translate(1.5 2.13)"/><path class="e" d="M164,309v57H734V309ZM420.5,195V480h57V195ZM164,480l190-95h42.48l-190,95Zm0-285,190,95H311.52L164,216.24Zm337.52,95,190-95H734L544,290H501.52ZM734,480,544,385h42.48L734,458.76Z" transform="translate(1.5 2.13)"/></g><path class="f" d="M-1.5,187.87h380v95H-1.5Z" transform="translate(1.5 2.13)"/><path d="M-1.5-2.13h380v95H-1.5Z" transform="translate(1.5 2.13)"/><path class="g" d="M-1.5,92.87h380v95H-1.5Z" transform="translate(1.5 2.13)"/></svg>');    
}

//it
.country-5, .country-fr {
    background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" height="480" width="640" viewBox="0 0 640 480">  <g fill-rule="evenodd" stroke-width="1pt">    <path fill="#fff" d="M0 0h640v480H0z"/>    <path fill="#00267f" d="M0 0h213.337v480H0z"/>    <path fill="#f31830" d="M426.662 0H640v480H426.662z"/>  </g></svg>');
}

//it
.country-6, .country-it {
    background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" height="480" width="640" viewBox="0 0 640 480">  <g fill-rule="evenodd" stroke-width="1pt">    <path fill="#fff" d="M0 0h640v479.997H0z"/>    <path fill="#009246" d="M0 0h213.331v479.997H0z"/>    <path fill="#ce2b37" d="M426.663 0h213.331v479.997H426.663z"/>  </g></svg>');
}

//nl
.country-7, .country-nl {
    background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" height="480" width="640" viewBox="0 0 640 480">      <g fill-rule="evenodd" stroke-width="1pt" transform="scale(1.25 .9375)">        <rect rx="0" ry="0" height="509.76" width="512" fill="#fff"/>        <rect rx="0" ry="0" height="169.92" width="512" y="342.08" fill="#21468b"/>        <path fill="#ae1c28" d="M0 0h512v169.92H0z"/>      </g>    </svg>');  
}

//pl
.country-8, .country-pl {
    background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" height="480" width="640" viewBox="0 0 640 480"><g fill-rule="evenodd"><path fill="#fff" d="M640 480H0V0h640z"/><path fill="#dc143c" d="M640 480H0V240h640z"/></g></svg>');  
}

.country-9, .country-dk {
    background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" height="480" width="640" viewBox="0 0 640 480"><path fill="#c60c30" d="M0 0h640.1v480H0z"/><path fill="#fff" d="M205.714 0h68.57v480h-68.57z"/><path fill="#fff" d="M0 205.714h640.1v68.57H0z"/></svg>');

}

.country-10, .country-se {
    background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" height="480" width="640" viewBox="0 0 640 480">  <defs>    <clipPath id="a">      <path fill-opacity=".67" d="M-53.421 0h682.67v512h-682.67z"/>    </clipPath>  </defs>  <g clip-path="url(#a)" transform="translate(50.082) scale(.9375)">    <g fill-rule="evenodd" stroke-width="1pt">      <path fill="#006aa7" d="M-121.103.302h256V205.1h-256zM-121.103 307.178h256v204.8h-256z"/>      <path fill="#fecc00" d="M-121.103 204.984h256v102.4h-256z"/>      <path fill="#fecc00" d="M133.843.01h102.4v511.997h-102.4z"/>      <path fill="#fecc00" d="M232.995 205.013h460.798v102.4H232.995z"/>      <path fill="#006aa7" d="M236.155 307.208h460.797v204.799H236.155zM236.155.302h460.797V205.1H236.155z"/>    </g>  </g></svg>');

}




