svg#zipMap {
    min-width: 420px;
    width: 100%; 
    max-height: 320px;

    

    g {
        cursor: pointer;
        * {
            transition: fill linear .25s;
        }        
        &:hover {
            *:not(.st1) {
                fill: #0080c6;
            }
        }
    }
}

.area-0 {
    g#area-00 {
        *:not(.st1) {
            fill: #0080c6;
        }
    }        
}

.area-1 {
    g#area-01 {
        *:not(.st1) {
            fill: #0080c6;
        }
    }        
}

.area-2 {
    g#area-02 {
        *:not(.st1) {
            fill: #0080c6;
        }
    }        
}

.area-3 {
    g#area-03 {
        *:not(.st1) {
            fill: #0080c6;
        }
    }        
}

.area-4 {
    g#area-04 {
        *:not(.st1) {
            fill: #0080c6;
        }
    }        
}

.area-5 {
    g#area-05 {
        *:not(.st1) {
            fill: #0080c6;
        }
    }        
}

.area-6 {
    g#area-06 {
        *:not(.st1) {
            fill: #0080c6;
        }
    }        
}

.area-7 {
    g#area-07 {
        *:not(.st1) {
            fill: #0080c6;
        }
    }        
}

.area-8 {
    g#area-08 {
        *:not(.st1) {
            fill: #0080c6;
        }
    }        
}

.area-9 {
    g#area-09 {
        *:not(.st1) {
            fill: #0080c6;
        }
    }        
}

.area-nl {
    g#area-nl {
        *:not(.st1) {
            fill: #0080c6;
        }
    }        
}

.area-at {
    g#area-at {
        *:not(.st1) {
            fill: #0080c6;
        }
    }        
}

.area-ch {
    g#area-ch {
        *:not(.st1) {
            fill: #0080c6;
        }
    }        
}