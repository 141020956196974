@import 'variables';
@import 'functions';
@import 'keyframe_animations';
@import 'fresco';
@import "iziModal";
 
 
* {
    font-weight: normal;
//     image-rendering: -webkit-optimize-contrast;
}

/* input, select, textarea {

	    @media (max-width: 620px) {
	        font-size: 16px !important;
	    }     
    
} */

html {
    font-size: 62.5%;
    height:100%;
}

body {
    padding: 0;
    margin: 0;
    font-family: "ff-dax-web-pro","Arial Narrow","Arial";
    color: $colFont;
    line-height: 1.4;
    min-height: 100%;
    position: relative;
    padding: 0 2rem;
    @media (max-width: 720px){
        padding: 0 1rem;
    }  
    &.catalogSelection {
        @media (min-width: 1090px) {   
            overflow-x: hidden;
        } 
    }
}

// /* applies to GIF and PNG images; avoids blurry edges */
// img[src$=".gif"], img[src$=".png"] {
//     image-rendering: -moz-crisp-edges;         /* Firefox */
//     image-rendering:   -o-crisp-edges;         /* Opera */
//     image-rendering: -webkit-optimize-contrast;/* Webkit (non-standard naming) */
//     image-rendering: crisp-edges;
//     -ms-interpolation-mode: nearest-neighbor;  /* IE (non-standard property) */
// }

img {
    border: none;
}

a {
    color: #0080C6;
    text-decoration: underline
}

p, ul, ol {
    font-size: 1.4rem;
}

hr {
    background: none;
    margin: 0 0 4rem;
    padding-top: 4rem;
    border: none;
    border-bottom: 1px solid #ACE0F8;
    clear: both;
}

.cms-text {
    
    max-width: 720px;
    
    h2 {
        text-transform: none;
        margin: 2.4rem 0 .6rem;
        font-size: 2.1rem;
        font-weight: 600;
    }
    
    h3 {
        font-weight: 600;
        margin: 2rem 0 0; 
        font-size: 1.6rem;
    }
    p {
        font-size: 1.6rem;   
        margin: 0 0 1.12rem; 
        
        &.lead {
            font-size: 2.2rem;
            margin: 4rem 0 0rem; 
        }
        &.center {
            text-align: center;
        }
    }
    
}

#page {
    box-sizing: border-box;
    max-width: 1090px;
    margin: 0 auto;
    background-color: #fff;
    
    &:before {
        content: '';
        position: absolute;
        z-index: 0;
        left: 0;
        right: 0;
        height: 162px;
        background: $colBgLight;
        @media(max-width: 720px) {
            height:105px;
        }
    }
}


@import 'landingPage';
@import 'landingPageProductSelection';
@import 'companyPage';
@import 'grid_fallback';
@import 'grid-ms';
@import 'grid';
@import "tabs";
@import "header";
@import "footer";
@import "teaser_depot";
@import "list_depots";
@import "table";
@import "flags";
@import "modal";
@import "map"; 
@import "body_text";
@import "catalog_content";
@import "cookieconsent";
@import "ronden";
@import "download";
@import "depots";
@import "videos";

 

section {
    display: flex;
}

aside {
    /*
    width: 26rem;
    margin-right: 2rem;*/
    margin-top: 13.2rem;
    @media (max-width: 720px) {
        margin-top: 2rem;
    }

    h2 {
        font-size: 2.4rem;

    }
    
    .back {
        
        display: inline-block;
        font-size: 1.4rem;
        line-height: 1.2;
        margin: 4rem 0 0rem;
        
        text-decoration: none;
        
        &:before {
            content: '';
            background: url(../../images/arrow.svg) no-repeat;
            background-size: 7px auto;
            background-position: 0 0;
            transform: rotate(180deg);
            display: inline-block;
            width: 7px;
            height: 20px;         
        }
        
	    @media (max-width: 620px) {
	        display: none;
	    }        
    }


}

.catalogArticle  {
    aside {
        margin-top: 9rem;
	    @media (max-width: 620px) {
	        margin-top: 3rem;
	    }             
        
    }
}

.categories {
    ul {
        font-size: 1.4rem;
        line-height: 1.2;
        list-style: none;
        padding: 0;
        text-transform: uppercase;

        ul {
            margin-left: 1.2rem;
            margin-top: 1.2rem;
            margin-bottom: 3rem;
            text-transform: none;
        }
        
        & > li > ul ul {
            display: none;  
        }

        li {
            margin: .6rem 0;
            padding-left: 1.2rem;
            background: url(../../images/arrow.svg) no-repeat;
            background-size: 7px auto;
            background-position: 0 2px;
        }

        a {
            color: #0065A4;
            text-decoration: none;
        }

        .section, .current {
            font-weight: 600;
            color: #1aa0e1;
        }
    }

}

.media {
    padding-right: 1.25%;
    box-sizing: border-box;

    h2 {
        font-size: 3.2rem;
        text-transform: uppercase;
        margin: 0 0 1rem;
    }

    .articleImageContainer {
        width: 100%;
        height: 0;

        padding-bottom: 75%;
        border: 1px solid #0077BF;

        box-sizing: border-box;
        text-align: center;
        position: relative;
    }
    .articleImage {
        display: block;
        max-width: 100%;
        margin: 0 auto;
        position: absolute;
        left: 2rem;
        right:2rem;
        bottom: 2rem;
        top: 2rem;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;


    }
    
    .articleVideo {
        margin-top: 1rem;
        width: 8rem;
        height: 8rem;
        display: inline-block;

        overflow: hidden;
        position: relative;
        &:after, &:before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
          }
          &:after {
            width: 0;
            height: 0;
            border: 16px solid transparent;
            border-left: 20px solid #fff;
            margin-top: -18px;
            margin-left: -7px;
            -webkit-filter: drop-shadow(0px 0px 2px rgba(0,0,0,0.25));
          }
          &:before {
            margin-top: -34px;
            margin-left: -34px;
            width: 60px;
            height: 60px;
            border: 3px solid #fff;
            border-radius: 50%;
            box-shadow: 0 0 4px rgba(black, 0.18);
            background: rgba(black, 0.42);
          }
          &:hover {
            &:before {
              background: rgba(black, 0.7);
            }
          }

        img {
            display: block;
            height: 100%;
            width: auto;
        }


    }


}

main {
    padding-top: 6rem;
    @media (max-width: 720px) {
        padding-top: 4rem;
        
    }    

    h2 {
        font-size: 3.2rem;
        letter-spacing: .1rem;
        text-transform: uppercase;
        margin: 0;
        line-height: 1.1;
        margin-bottom: 1rem;
    }

    h3 {
        font-size: 1.8rem;
        margin: 0 0 3.6rem;
        b {
            font-weight: 600;
        }
    }

}

.catalogArticle main {
    padding-top: 14.2rem;
    @media (max-width: 540px) {
        padding-top: 3.2rem;           

    }      

    label {
        color: #7F8185;
        display: block;
        font-size: 1.4rem;
        margin-bottom: .6rem;
    }
}

select {
    background: #FFF no-repeat 95% center;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA4LjYgNi4xMyI+PGRlZnM+PHN0eWxlPi5he2ZpbGw6IzZkNmU3MDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93LXNlbGVjdDwvdGl0bGU+PHBvbHlsaW5lIGNsYXNzPSJhIiBwb2ludHM9IjguNiAxLjk3IDQuMzMgNi4xMyAwIDIuMDIgMCAwLjA1IDQuMzUgNC4xNSA4LjYgMCA4LjYgMS45NyIvPjwvc3ZnPg==");

    background-size: 8px auto;

    font-size: 1.2rem;
    border: 1px solid #71cef3;
    color: #6D6E70;

    border-radius: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding: .5rem .5rem .4rem;
    padding-right: 2.4rem;
    line-height: 1.2;
    text-shadow: none;   
    
    font-family: "ff-dax-web-pro","Arial Narrow","Arial";

    &.dark{
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA4LjYgNi4xMyI+PGRlZnM+PHN0eWxlPi5he2ZpbGw6I0ZGRjt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93LXNlbGVjdDwvdGl0bGU+PHBvbHlsaW5lIGNsYXNzPSJhIiBwb2ludHM9IjguNiAxLjk3IDQuMzMgNi4xMyAwIDIuMDIgMCAwLjA1IDQuMzUgNC4xNSA4LjYgMCA4LjYgMS45NyIvPjwvc3ZnPg==");

        background-color: #1279BC;
        color: #FFF;
        border: 1px solid #1279BC;
    }

}


.download-list {
    font-size: 1.8rem;
    line-height:1.8rem;
    .fa-li {
        top: 1rem;
    }
    li {
        padding-bottom: 1rem;
        margin-bottom: 1rem;
        border-bottom: 1px solid #ACE0F8;
    }
    li:last-child {
        border-bottom: none;
        padding-bottom: 0rem;
        margin-bottom: 0rem;
    }
    a {
        text-decoration: none;
        small {
            font-size: 1.4rem;
        }
    }
}

.catalogInfo {
    text-align: left;
    width: 100%;
    font-size: 1.6rem;
    position: relative;    
}



#sortArticles {
    text-align: right;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    @media(max-width: 720px) {
        align-items: flex-start;
        justify-content: flex-start;
    }


    select {
        display: block;
        margin-left: 1rem;
        display: inline-block;
        @media (max-width: 720px) {
            margin-right: 1rem;
            margin-left: 0;
        }
    }
}

.articles {
    margin: 2rem 0;
    overflow: hidden;
   

    /*
        @supports(display: grid) {


            display: grid;
            grid-gap: 2rem;
            grid-template-columns: 1fr 1fr 1fr;

        }
    */
}

.catalogSearch, .catalogPromotions, .catalogNewArticles, .catalogSelection {
    article {
        width: 22.75%;
        padding-bottom: 22.75%;
        cursor: pointer;
        
        &:nth-child(1n) {
            margin-right: 3%;
        }
        &:nth-child(4n) {
            margin-right: 0;
        }
        @media (max-width: 590px) {
            width: 48%;
            padding-bottom: 48%;
            &:nth-child(3n) {
                margin-right: 4%;
            }
            &:nth-child(2n) {
                margin-right: 0;
            }
    
        }        
    }

}

article, .articleImageContainer {

    &.aktion {
        &:after {
            background-image: url(../../images/aktion.png);
            content: ' ';
            position: absolute;
            left: 1.5rem;
            bottom: 2.75rem;            
            background-repeat: no-repeat;
            width: 18.5%;
            height: 18.5%;
            background-size: contain;
            z-index: 3;
            image-rendering: -webkit-optimize-contrast;
        }
        &.new {
            &:after {
                left: auto;
                right: 1.5rem;
                top: 6.5rem;
         
            }
        }        
    }
    
    &.new {
        &:before {
            background-image: url(../../images/neu.png);
            content: ' ';
            position: absolute;
            left: 1rem;
            bottom: 1.75rem;
            background-repeat: no-repeat;
            width: 25%;
            height: 25%;
            background-size: contain;
            z-index: 3;  
            image-rendering: -webkit-optimize-contrast;        
        }

    }
    
  
}

.teaserBox-01 .highlightProduct article {
    
    background: lighten(#daf0fd, 5%);
}

.highlightedArticlePreview {
    .moreProducts {
        clear: both;
        margin: 0;
        padding: 2rem 0 0;
    }
}

.highlightedArticle {
    article {
        opacity: .5;
        transition: opacity .25s linear;
        &:hover {
            opacity: 1;
        }
    }
    article.highlight {
         opacity: 1;
    }
}

article {
    position: relative;
    width: 30.66666%;
    border: 1px solid #1279BC;
    height: 0;
    padding-bottom: 30.66666%;
    float: left;
    margin-right: 4%;
    margin-bottom: 4%;
    overflow: hidden;

    

    &:nth-child(3n) {
        margin-right: 0;
    }

    @media (max-width: 590px) {
        width: 48%;
        padding-bottom: 48%;
        &:nth-child(3n) {
            margin-right: 4%;
        }
        &:nth-child(2n) {
            margin-right: 0;
        }

    }

    box-sizing: border-box;
    a {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        text-decoration: none;
        padding: 1.2rem;
        box-sizing: border-box;

    }

    h3 {
        position: relative;
        z-index: 2;
        margin: 0;
        padding: 0 0 .4rem;
        text-transform: uppercase;
        color: #0077BF;
        border-bottom: .5px solid #1279bc;
        font-size: 1.4rem;
        background: #FFF;
    }

    .article-image {
        z-index: 1;
        position: absolute;
        left: 1rem;
        top: 4rem;
        bottom: 5rem;
        right: 1rem;
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
        /*
                @supports(display: grid) {
                    position: static;
                    display: block;
                    margin: 1rem 1rem 4rem;
                    min-height: 132px;

                }
        */

    }

    .price-box {
        z-index: 2;
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        background: #19AB8D;
        color: #FFF;
        padding: .8rem 1.2rem .2rem;
        text-align: right;
        font-family: FFFagoWebPro;

        &:after {
            position: absolute;
            content: '';
            width: 0;
            height: 0;
            border-left: 20px solid transparent;
            border-right: 20px solid transparent;
            border-bottom: 20px solid #19AB8D;
            transform: rotate(90deg);
            right: 0;
            bottom: 75%;
        }

        .uvp {
            font-size: 1.4rem;
            margin-right: 1.2rem;
            font-family: 'FFFagoWebPro', 'ff-dax-web-pro-condensed';
            letter-spacing: .05rem;
            @media (max-width: 360px) {
                margin-right: .6rem;
                font-size: 1.2rem;
                
            }            
            
        }
        .on-request {
            

            font-family: 'FFFagoWebPro', 'ff-dax-web-pro-condensed';
            letter-spacing: .05rem;
            position: relative;
            z-index: 2;
            @media (max-width: 360px) {
                margin-right: .6rem;
                font-size: 1.2rem;
                
            }       
            
            span {
                font-size: 1.8rem;
                text-transform: uppercase;
            }
            
        }     
        .unavailable {
            font-size: 13px;
            line-height: 16px;
            display: block;
            position: relative;
            z-index: 2;
            top: -1px;
            text-transform: uppercase;        
        }   
        .price {
            font-size: 24px;
            position: relative;
            z-index: 2;
            span {
                margin-left: 4px;
            }
        }
        &.teaser-box {
            
            &:after {
                top: -10px;
            }
            
            p {
                text-transform: uppercase;
                font-size: 2.4rem;
                text-align: center; 
                @media (max-width: 640px) {
                    font-size: 1.4rem;
                                               
                }
            }
            
            .cta {
                font-size: 1.4rem;
                text-transform: none;
                text-decoration: underline;
            }

        }        
    }
    .teaser-image {
        bottom: 16rem;
        image-rendering: auto;
    }

}

.pagination {
    text-align: right;
    margin-bottom: 3.6rem;
    a {
        display: inline-block;
        padding: .2rem;
        margin-left: .2rem;
        font-size: 1.4rem;;
        color: #9C9EA0;
        text-decoration: none;
    }
    .MarkupPagerNavOn a span {
        color: #58595B;
        font-weight: 600 !important;
    }
}


.breadcrumbs {
    box-sizing: border-box;
    padding-top: 3rem;
    position: relative;
//     z-index: 4;

    @media (max-width: 720px) {
        padding-top: 2rem;
        padding-left: 13rem;
        
        display: none;
    }

    a, span {
        color: #6D6E70;
        font-size: 1.2rem;
        letter-spacing: .05rem;
        text-decoration: none;
    }

    span {
        color: darken(#6D6E70, 35%);
        font-weight: 600;
    }
    
    a {
        font-weight: 500;
    }

}



.shopInfos {
    display: flex;
    justify-content: center;
    
    display: -ms-flexbox;
    -ms-flex: 1;   
    -ms-flex-pack: justify;       
    
    padding: 0 4rem;
    @media (max-width: 890px) {
        flex-wrap: wrap;
        -ms-flex-wrap: wrap; 

    }
    @media (max-width: 540px) {
        padding: 0;
    }

    .shopInfo {
        border: 2px solid #ACE0F8;
        border-radius: 1rem;
        margin: 1rem;
        padding: 1rem;
        box-sizing: border-box;
        min-width: 220px;
        max-width: 320px;
        display: flex;
        justify-content: space-between;
        
        display: -ms-flexbox;
        -ms-flex: 1;   
        -ms-flex-pack: justify;         
        
        @media (max-width: 540px) {
            width: 100%;
            max-width: auto;

        }


        img {
            order: 1;
            -ms-flex-order: 1;
            box-sizing: border-box;
            padding-left: 1rem;
            width: 28.5%;
            height: 100%;

        }
        h3 {
            order: 2;
            -ms-flex-order: 2;
            width: 67.5%;
            box-sizing: border-box;
            padding: 1rem;
            color: #333;
            font-size: 2rem;
            line-height: 1.2;
            letter-spacing: .05rem;
            margin: 0;
            //hyphens: auto;
            font-family: ff-dax-compact-web-pro;
            font-weight: 300;
        }
    }
}


.productSlider {
    z-index: 2;
    img {
        width: 100%;
        display: block;
    }

}

.productSlidesBg {
    position: absolute;
    background: #DAF0FD;
    left: 0;
    right: 0;
    z-index: 1;
    height: 27.7%;
}

.productSlides {
    @media (max-width: 720px) {
        margin-left: -1rem;
        margin-right: -1rem;

    }
}

.vcard {

    font-size: 1.4rem;
    font-size: 1.4rem;
    border-bottom: 1px solid #ace0f8;
    padding-bottom: 2rem;    
    
    &:last-child {
        border-bottom: none;
    }
    
    h3 {
        margin-bottom: 0;
    }    
    
}

.catalogWrap {
    overflow: hidden;
    margin: 0 -1rem;
}

.catalogList {
    
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    justify-content: space-evenly;
    align-items: flex-end;
    
    display: -ms-flexbox;
    -ms-flex-pack: space-evenly;
    -ms-flex-wrap: wrap;

    margin: 0 -1rem;
    padding: 0;
    
      
    & > li {
        box-sizing: border-box;
        padding: 0 2rem;
        width: 33.33333%;
        margin-bottom: 2rem;
        
        @media (max-width: 720px) {
            width: 50%;
            justify-content: flex-start
    
        }
        
        @media (max-width: 420px) {
            width: 50%;
            justify-content: flex-start
    
        }                  
        
        h3 {
            margin: 0 0 .5rem;
        }
        
        
        img {
            box-shadow: 0 0 1.5rem rgba(0,0,0,.25); 
            display: block;
            width: 100%;
            margin-bottom: 2rem;
            transition: transform linear .125s; 
           
            &:hover {
                transform: scale(1.01);
            }
        }
        
        .fileSize {
            
            display: block;
            
        }
        
        .fileDownload {
            display: block;
        }
        
    }
}

#formModal {
    .cms-text {
        padding: 1rem 2rem 2rem;
    }
}

.triggerForm {
    cursor: pointer;
}

.inline-form {
    margin: 2rem 0;
}


.catalogSelection, .landingPageRonden, .landingPageRondenOverview {
    header {

    
    }
    
    .breadcrumbs {
        display: none;
    }
}


.section-start {
    margin: 3.2rem 0;
    text-align: center;
    color: #1a1919;
    padding: 0;
    
    &:after {
        content: '';
        display: block;
        margin: .75rem auto;
        width: 200px;
        border-bottom: 2px solid #aaa9a9;
        
    }
    
}

.quick-info-boxes {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
            align-items: stretch;
    
    margin: 0;
    padding: 0;
    list-style: none;
    
    & > li {
        
        width: 19.5%;
        box-sizing: border-box;
        position: relative;
        
        background: #0f4b83;
        
        & > a {
            padding: 1rem;
            display: block;
            
            color: #FFF;
            font-size: 1.6rem;
            text-decoration: none;
            

        }
        &:hover {
           background: lighten(#0f4b83, 5%);
        }        
        
        &.active {
            background: #15986d; 
            &:hover {
                background: #15986d;
            }            
   
            
            .info-box {
                opacity: 1;
                pointer-events: all;
                height: auto;
            }                   
        }
        
        &:last-child {
            .info-box {
                left: auto;
                right: 0;
            }
        }
        @media (max-width: 720px) {
            &:nth-child(3), &:nth-child(4) {
                .info-box {
                    left: auto;
                    right: -154.8076923%;
                }                
            }    
        }        
        

      
        
    }
    
    .info-box {
        
        pointer-events: none;
        overflow: hidden;
        
        opacity: 0;
        transition: opacity 200ms linear;
        
        position: absolute;
        z-index: 10;
        box-sizing: border-box;
        
        padding: 1rem;
        margin-top: .5rem;
        
        left: 0;
        width: 203.2051282%;
        
        @media (max-width: 720px) {
            margin-top: 0;
            width: 409.6153846%;
            top: 70px;
    
        }
               
        
        color: #4e5350;
        background: #d9ede4;
            
        hr {
            margin: 1rem 0;
            padding: 0;
            border-bottom-color: #899590;
        }
        
        ul {
            list-style: disc;
            padding-left: 2rem;
        }
        
        li {
            margin-bottom: 0.25rem;
            font-size: 1.4rem;
        }
        
        h3 {
            margin: 1rem 0;
            font-size: 1.6rem;
            color: #5687a5;
        }
        
    }
    
    
}

.landing-light, .landing-dark {
    display: block;
    padding: 3.2rem 0;
}

.landing-dark {
    background: #dcf0fb;
    position: relative;

    
    @media (min-width: 1090px) {


          
        &:after {
            position: absolute;
            content: '';
            
            width: 1000px;
            left: 100%;
            background: #dcf0fb;
            top:0;
            bottom: 0;        
        }
        
        &:before {
            position: absolute;
            content: '';
            
            width: 1000px;
            right: 100%;
            background: #dcf0fb;
            top:0;
            bottom: 0;
        }  
    }   

    
}

.faded {
    opacity: .25;
    pointer-events: none;
}

.can-fade {
    transition: opacity 300ms linear;    
}

#datenTable {
    display: none;
    margin-bottom: 3.2rem;
    overflow: auto;
}

.specificationsTable {
    
    min-width: 640px;
    
    &.open {
        display: table;
    }
    
    margin: 0 auto;
    background: transparent;
    border: none;
    box-sizing: border-box;
    width: 90%;
    table-layout: fixed;
    
    
    th {
        width: 19%;
        
        img {
            max-width: 100%;
        }
    }
    
    th:first-child {
        width: 24%;
        
    }
    
    th, td {
        padding: .75rem 1rem;
    }
    
    thead {
        th {
            background: #275a7e;
            color: #FFF;
            border-right: 1px solid #FFF;
            font-weight: normal;
            text-align: center;

            

            &:last-child {
                border-right: none;
            }
            &:nth-child(2) {
                background: #56849c;
            }
        }
        
        .empty, tr, tr:first-child {
            background: transparent;
            border-right: none;
        }
        
        .emptyRow {
            th {
                background: #dbeff5;
                padding: 3px;
            }
        }
        
        .imageRow {
            td, th {
                background: none;
                padding: 4px 2px;
            }
        }
        
    }
    
    tbody {
        
        td {
            background: #bce5f7;
            
 
        }
        .light td {
           background: #FFF; 
        }        
        
        td, th {
            border: 1px solid #85a6bf;
            border-right: none;
            border-bottom: none;  
            text-align: center;
            font-size: 1.4rem;
            line-height: 1.4;
            vertical-align: top;
            color: #262727;
            
            &:last-child {
                border-right: 1px solid #85a6bf;
            }    
            &:first-child {
                text-align: left;
            }      
        }
        tr:last-child {
            th, td {
                border-bottom: 1px solid #85a6bf; 
            }
        }
                
        .headerRow {
            background: darken(#bce5f7, 10%);
            
            
            th {
                color: #262929;
                font-weight: normal;
                text-align: center;

            } 
            th:first-child {
                font-weight: 700;
                text-align: left;
            }
        }
        
        .emtpyRow {
            td {
                background: #FFF;
            }
        }
        

    }
}

.toggleIcon {
    width: 64px;
    height: 64px;
    display: block;
    margin: 0 auto;
    text-align: center;
    background: url(../../images/lupe.png) no-repeat;
    background-size: contain;
    
    &.open {
        background-image: url(../../images/close.png);
    }
}

.productInfo {
    position: relative;
    
    h3 {
        position: relative;
        z-index: 2;
    }
}

.specialOffer img {
    max-width: 140px;
    text-decoration: none;
    border: none;
    position: absolute;
    right: 0;
    bottom: -2px;
}
