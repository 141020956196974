@use '../base/colors' as *;

:root {
  --aa-search-input-height: auto;
}

#search {
  input {
    border: none;
    padding: .8rem 1.2rem .6rem;
    border-radius: 4px;
    font-family: "ff-dax-web-pro";
    font-size: 1.4rem;
    width: 100%;
    box-sizing: border-box;
    text-overflow: ellipsis;
    @media (max-width: 720px) {
      max-width: none;
    }
  }
}

.aa-Label svg, .aa-LoadingIndicator svg {

}
.aa-LoadingIndicator, .aa-SubmitButton {
  padding: .25rem .75rem 0;
  width: auto;
  svg {
    color: $colBgDark;
    width: 1.75rem;
  }
}

.aa-Panel {
  z-index: 10;
}

.aa-List {
  width: 100%;
}

.aa-Item {

  height: auto;

  &[aria-selected=true] {
    background-color: $colBgLight;

    a {
      color: #FFF;
    }
  }

  a {
    width: 100%;
    text-decoration: none;
    color: $colFont;
    display: grid;
    align-items: center;
    column-gap: 1rem;
    grid-template-areas: 'image name' 'image variant';
    grid-template-columns: 3rem 1fr;
  }
  img {
    height: 3rem;
    width: 3rem;
    object-fit: cover;
    grid-area: image;
  }

  span {
    grid-area: name;
  }

  small {
    grid-area: variant;
  }


}


